const apiKey='CwUKlsPxxdtcOoRCmCTt';
const secret='eEOAElYBRxRjQlhzxpuESZBgQgdHSKrl';

let Discogs = {
search(id){
return fetch(`https://api.discogs.com/releases/${id}?key=${apiKey}&secret=${secret}`,{
    header:{
        'User-Agent':'MyRecordDealer/0.1+http://localhost:3000/Dealers'
    }
}).then(response=>{
   return response.json()
}).then(jsonResponse=>{
    if(jsonResponse){
        return ({
            title: jsonResponse.title,
            artist: jsonResponse.artists_sort,
            thumbnail: jsonResponse.thumb,
            link: jsonResponse.uri,
            id:jsonResponse.id,
            country:jsonResponse.country,
            date:jsonResponse.released_formatted,
            tracklist:jsonResponse.tracklist,
            formats:jsonResponse.formats,
            styles:jsonResponse.styles,
            catnb:jsonResponse.labels[0].catno,
            label:jsonResponse.labels[0].name,
            genre:jsonResponse.genres

        })
    }
})
    }
};

export default Discogs;