import React from 'react';
import SearchBarVideo from '../SearchBarVideo/SearchBarVideo';
import SearchVideoResults from '../SearchVideoResults/SearchVideoResults';
import ShowVideo from '../ShowVideo/ShowVideo';
import './VideoArchives.css';


class VideoArchives extends React.Component{
  
render()
{
    return(

        <div className="VAContainer">
            <div className="SearchBarVideoCont">
            <SearchBarVideo 
            onSearch={this.props.searchForVid}
            />
            </div>
            <div className="MainContainer">
                <div className="SVResults">
                    <SearchVideoResults 
                    data={this.props.state.searchResults} 
                    displayVid={this.props.displayVid} 
                    />
                </div>
                <div className="MainVideoFrame">
                    <ShowVideo 
                    show={this.props.state.vidToShowVA}
                    />
                </div>
            </div> 
        </div>


    
        );
    }
}

export default VideoArchives;