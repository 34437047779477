import React from 'react';
import Dealer from '../Dealer/Dealer';
import './HotDealers.css';

class HotDealers extends React.Component{

    render()
    {
        return(
        <div className="ContainerHotDealers">
            
            <div className="HDTitle">
                <h3>Hot Dealers</h3>
            </div>
            <div className="EachHD">
            {this.props.hotDealers.map(dealr=>
            {
                return(
                <Dealer
                dealr={dealr}
                hotDealers={this.props.hotDealers}
                // the props below this line are only used for handling user actions and popup to display accordingly.
                objectTR={this.props.objectTR}
                vidForHD={this.props.vidForHD}
                recoForHD={this.props.recoForHD}
                showPopup={this.props.showPopup}
                showSVP={this.props.showSVP}
                showSRP={this.props.showSRP}
                gifHDRR={this.props.gifHDRR}
                togglePopup={this.props.togglePopup}
                />);
            }
            )
            }
            </div>
        
        </div>
        );
    }
}


export default HotDealers;