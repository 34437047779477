import React from 'react';
import VideoBar from '../VideoBar/VideoBar';
import RecordBar from '../RecordBar/RecordBar';
import './DealerDetailsList.css';

class DealerDetailsList extends React.Component{

    render()
    {
        if(this.props.show.length>0 && !this.props.hotDealers)
        {
            return(
            <div className="DDContain">
                <div className="ImgDetDD">
                    <img 
                    className="ImgDetImgDD"
                    src={this.props.show[0].logo} 
                    height="200" width="200" 
                    alt="logo shop"
                    />
                </div>
                <div className="DDText">
                    <div className="TextDetails">
                        <h2 className="ShopTitle">
                            {this.props.show[0].name.join(' ')}
                        </h2><br/>
                        <h4 className="DDAddress" >
                            {this.props.show[0].address}
                        </h4>
                        <h4 className="TextDetDealOpen">
                            {this.props.show[0].open}{this.props.show[0].holiday}
                        </h4><br/>
                        {this.props.show[0].description.length<300 ?
                        <h4 className="TextDetDeal">
                        {this.props.show[0].description}
                    </h4> : <h4 className="TextDetDealBig">
                    {this.props.show[0].description}</h4>}<br/>
                        <a className="LinkToW" href={this.props.show[0].url}>
                            Website
                        </a>
                    </div>
                </div>
                <div className="ContainerVidBarDdetails">
                    <VideoBar 
                    change={this.props.change} 
                    vidToShow={this.props.vidToShow} 
                    showVidPop={this.props.showVidPop} 
                    show={this.props.show[0]}
                    />
                </div>
                <div className="ContainRecoBarDdetails">
                    <RecordBar 
                    showPop={this.props.showPop} 
                    recoTR={this.props.recoTR} 
                    change={this.props.change} 
                    show={this.props.show[0]}/>
                </div>
            </div>
                        );
        }else{return(null);}

    }
}

export default DealerDetailsList;