import React from 'react';
import Record from '../Record/Record';
import './HotRecords.css';

class HotRecords extends React.Component{

    render()
    {console.log(this.props.hot)
        return(
        <div className="ContainHotRecords">

            <h3 className="HRTitle">
                Hot Records
            </h3>
            <div className="HotRecoz">
                {this.props.hot.map(item=>
                {console.log(item)
                    return(
                    <div className="ContReco">
                    <Record 
                    showPopup={this.props.showPopup} 
                    toggle={this.props.toggle} 
                    otr={this.props.otr} 
                    recoD={item}
                    />
                    </div>
                    )
                }
                )
                }
            </div>

        </div>
        );
    }
}

export default HotRecords;