import React from 'react';
import './Record.css';
import Popup from '../Popup/Popup';

class Record extends React.Component{
    constructor(props){
        super(props);
        this.state={
            objectToRender:[]
        };
        this.changeID=this.changeID.bind(this);
        this.changeIDforDD=this.changeIDforDD.bind(this);
        this.changeIDforSP=this.changeIDforSP.bind(this);

    }
// this changeID function will send the id of the record that has been clicked on in the Hot Record section,
//  back to the Home component and allow the Toggle Function to update 
// the state with the relevant record Info.

    changeID(){
        this.props.toggle(this.props.recoD.id)
    }
// this changeIDforDD function will send the id of the record that has been clicked on,
//  back to the Dealers component and allow the change Function to update 
// the state with the relevant record Info.

    changeIDforDD(){
        this.props.change(this.props.recoD.id)
    }
// this changeIDforSP function is used to send the clicked on record thumbnail info back to the Home component, and re-render the
// hot dealer component but this time with the small pop up switch on, and the clicked on record detail info displayed in that small popup.

    changeIDforSP()
    {
        this.props.gifHDRR(this.props.recoD.id)
    }

    render()
    {
// this part of the function is used to render a record item when displayed in the recordbar of the dealer's details that get displayed after a hot dealer was clicked on.

      if(this.props.gifHDRR)
        {return(
        <div className="RecoBox">

          <div className="RecordImage">
            <img 
            className="RecordImageImg"
            src={this.props.recoD.thumbnail} 
            onClick={this.changeIDforSP} 
            height="100" 
            width="100" 
            alt="album logo"
            />
          </div>
          <div className="RecoArtist">
            <h3>
              {this.props.recoD.artist}
            </h3>
            <h3 className="RecoTitle">
              {this.props.recoD.title}
            </h3>
          </div>
          {this.props.showSRP && this.props.recoD===this.props.recoForHD[0]?
          <Popup
          showRR={this.props.recoD}
          showDDRR={this.props.showDDRR}
          recoForHD={this.props.recoForHD}
          showSRP={this.props.showSRP}
          gifHDRR={this.props.gifHDRR}
          closePopup={this.changeIDforSP}
          />
          : null
          }
          
        </div>)
    }
// this part of the function below is used to display a record item after a Dealer has been clicked on in the Dealers section. Furthermore,
// if this record thumbnail has been clicked on, on-render it will carry correct information so that its popup component gets rendered as well.

    else if(this.props.change)
    {
      return(
      <div className="RecoBoxDD">
        <div className="RecordImage">
          <img 
          className="RecordImageImg"
          src={this.props.recoD.thumbnail} 
          onClick={this.changeIDforDD} 
          height="100" 
          width="100" 
          alt="album logo"
          />
        </div>
        {this.props.showPop ?
        <Popup
        dealrInfo={this.props.dealrInfo}
        closePopup={this.changeIDforDD}
        showRR={this.props.recoTR}
        />
        : null
        }
        <div className="RecoArtistDD">
          <h3>
            {this.props.recoD.artist}
          </h3>
          <h3 className="RecoTitleDD">
            {this.props.recoD.title}
          </h3>
        </div>
      </div>)
    }

// The case below covers the record objects that need to be rendered in the Hot Record section on the Home page. OnClick, the
// changeID function will send the info of the clicked on record back to the Home component, so that he can uses it to render the
// expected popup.

    return(
    <div className="RecoBox">

      <div className="RecordImage">
        <img 
        className="RecordImgHR"
        src={this.props.recoD.thumbnail} 
        onClick={this.changeID} 
        height="100" 
        width="100" 
        alt="album logo"
        />
      </div>
      <div className="RecoArtistHR">
        <h3>
          {this.props.recoD.artist}
        </h3>
        <h3 className="RecoTitleHR">
          {this.props.recoD.title}
        </h3>
      </div>
      {this.props.showPopup && this.props.otr[0]===this.props.recoD?
      <Popup
      closePopup={this.changeID}
      showHR={this.props.otr[0]}
      />
      : null
      }
    </div>
    );
    }
}

export default Record;