import React from 'react';
import './SearchBarDealers.css';

class SearchBarDealers extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            term: '',
            placeholder:'Shop, Genre or Location'
          };
        
    this.handleTermChange = this.handleTermChange.bind(this);
    this.searchDealer = this.searchDealer.bind(this);
    this.changePH=this.changePH.bind(this);
    this.maybeHandleSearch=this.maybeHandleSearch.bind(this);
    }

    changePH(){
      this.setState({
        placeholder:''
      })
    }

    searchDealer(){
      let prelim=this.state.term;
      
      let allTerms = prelim.split(" ");
      console.log(allTerms) 
        let allTermslc =[];
        allTerms.map(item=>
          {
            let output=item.toLowerCase();
            allTermslc.push(output);
          }
        )

        this.props.onSearch(allTermslc);
        this.setState({term: allTermslc});
    }

    handleTermChange(event) {
        this.setState({term: `${event.target.value}`});
      }
    
      maybeHandleSearch(e) {
        console.log(e);
        if (e.key === 'Enter') {
          e.preventDefault();
          this.searchDealer();
          return;
        }
       
      }

    render(){
return(
<div className="SearchBarDealers">
  <input className="InputBarDeal" onKeyDown={this.maybeHandleSearch}  onClick={this.changePH} placeholder={this.state.placeholder} onChange={this.handleTermChange} />
  <button className="SearchButDealers" onClick={this.searchDealer}>search</button>
</div>);
    }


};

export default SearchBarDealers;