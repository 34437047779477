import React from 'react';
import './Video.css';
import Popup from '../Popup/Popup';

class Video extends React.Component{
    constructor(props){
        super(props);
        this.showVideo=this.showVideo.bind(this);
        this.showVidArchive=this.showVidArchive.bind(this);
        this.getvidID=this.getvidID.bind(this);
        this.getvidIDforDD=this.getvidIDforDD.bind(this);
    }
// this function is used to send the url info of the video thumbnail that has been clicked on, back to the LatestVideo component.

    showVideo()
    {
        this.props.showVid(this.props.source.url)
    }

// this showVidArchive function is used to send back the url of the clicked on video to the Video Archive component, so it knows
// which video to display in big format.

    showVidArchive(){
        this.props.displayVid(this.props.vidForVA.url)
    }

// this getvidID function sends back the video url back to the Home component's
// getIdForHDRR function, so that the Hot Dealer popup knows which video thumbnail has just been clicked on.

    getvidID()
    {
        this.props.gifHDRR(this.props.vid.url)
    }
// this getvidID function sends the video url back to the Home component's
// change function, so that the Dealers component knows which video thumbnail has just been clicked on.

    getvidIDforDD(){
        this.props.change(this.props.viddd.url)
    }

    render()
    {

// this first if section is used by the LatestVideo component, to render the thumbnails of the Latest Video listed in the DB.

        if(this.props.showVid)
        {
            return(
            <div className="VidVid">
                <img
                className="VidVidImg" 
                src={this.props.source.thumb}
                height="100" 
                width="138"
                onClick={this.showVideo} 
                alt="Video"/>
            </div>)
        }
// The part below is used by the video bar of the Popup displayed after a Hot Dealer is clicked on, and its detailed are displayed.

        else if(this.props.vid)
        {
            let shopUpper=this.props.vid.shop.map(
            item=>
            {
            let maj=item.charAt(0).toUpperCase()
            let restLet=item.slice(1,item.length)
            return(maj.concat(restLet))
            }
            )

            let firstUpper=this.props.vid.date[0].charAt(0).toUpperCase()
            let restNorm=this.props.vid.date[0].slice(1,this.props.vid.date[0].length)
            let dateUpper=firstUpper.concat(restNorm)
            let newDate=[dateUpper,this.props.vid.date[1],this.props.vid.date[2]]

            return (
            <div className="ContImVid">
                
                <div className="ImVid">
                    <img 
                    className="ImVidImg"
                    src={this.props.vid.thumb} 
                    height="100" 
                    width="138" 
                    onClick={this.getvidID} 
                    alt="Video"/>
                </div>
                {this.props.showSVP && this.props.vid===this.props.vidForHD[0]?
                <Popup
                vid={this.props.vid}
                closePopup={this.getvidID}
                />
                : null
                }
                <div className="VAVInfo">
                    <h3 className="VidTitleDetails">
                        {shopUpper.join(' ')}
                    </h3>
                    <div className="DateVid">
                        {newDate.join(' ')}
                    </div>
                </div>

            </div>)
        }
// The else if below is used to render video thumbnails in the search result for the video archive section after a search has been performed.
        else if(this.props.vidForVA)
        {
            
            return(
            <div className="VAResultList">
                <div className="ImVid">
                    <img 
                    className="VidVidImgVA"
                    src={this.props.vidForVA.thumb} 
                    height="100" 
                    width="138" 
                    onClick={this.showVidArchive} 
                    alt="Video"
                    />
                </div>
            </div>
            )
        }

// the "else if" below renders the video that has been chosen in the results of the Video Archive search section, and displays it
// in bigger size in the space built for it.

        else if(this.props.showMV)
        {
            return(
            <iframe 
            className="VTSIFrame"
            width="600" 
            height="300" 
            src={this.props.showMV} 
            frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
            </iframe>
            )
        }
// the part of the function below is used to render video thumbnails in the Dealer's Details after a Dealer has been clicked on
// in the Dealers section. Then, if this one of the video thumbnail is clicked on, on re-render the popup conditions will both
// become true and display expected video popup.
        else
        {
            let shopUpper=this.props.viddd.shop.map(
                item=>
                {
                let maj=item.charAt(0).toUpperCase()
                let restLet=item.slice(1,item.length)
                return(maj.concat(restLet))
                }
                )
    
                let firstUpper=this.props.viddd.date[0].charAt(0).toUpperCase()
                let restNorm=this.props.viddd.date[0].slice(1,this.props.viddd.date[0].length)
                let dateUpper=firstUpper.concat(restNorm)
                let newDate=[dateUpper,this.props.viddd.date[1],this.props.viddd.date[2]]
                
            return(
            <div className="ContImVid">
                <div className="ImVid">
                    <img 
                    className="ImVidImg"
                    src={this.props.viddd.thumb} 
                    height="100" 
                    width="138"
                    onClick={this.getvidIDforDD} 
                    alt="Video"
                    />
                </div>
                {this.props.showVidPop && this.props.viddd===this.props.vidToShow[0]?
                <Popup
                closePopup={this.getvidIDforDD}
                vid={this.props.viddd}
                />
                : null
                }
                <div className="VAVInfo">
                    <h3 className="VidTitleDetails">
                        {shopUpper.join(' ')}
                    </h3>
                    <div className="DateVid">
                        {newDate.join(' ')}
                    </div>
                </div>
            </div>
            );
        }
}
}
export default Video;