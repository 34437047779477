import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import NavMenu from '../NavMenu/NavMenu';
import Dealers from '../Dealers/Dealers';
import AboutUs from '../AboutUs/AboutUs';
import Home from '../Home/Home';
import VideoArchives from '../VideoArchives/VideoArchives';
import LogoHeartbeat from './logoroundHB.png';
import LogoLoveVinyl from './LoveVinylLogo.png';
import LogoPlugSeven from './LogoPlugSeven.png';
import LogoFernandoRey from './LogoJosh4MRD.png';
import LogoPianola from './LogoPianola.png';
import LogoANFR from './LogoANF4MRD.png';
import LogoUniversounds from './LogoUniversounds.png';
import LogoRareGroove from './LogoRareGroove.png';
import LogoGBR from './LogoGBR.png';
import LogoRanamusica from './LogoRanamusica.png';
import LogoNipponGroove from './LogoNipponGroove.png';
import LogoCosmos from './LogoCosmos.png';
import LogoElla from './LogoElla.png';
import LogoOrganicMusic from './LogoOrganicMusic.png';
import CoverWSAsagao from './WorldStandard.jpg';
import CoverPitchshifters from'./Pitchshifters.jpg';
import LogoListen from './LogoListen.png';
import Discogs from '../../util/Discogs/Discogs';
import Logomrd from './LogoMRD.png';


class App extends React.Component{
  constructor(props){
    super(props);
    this.state={
      searchResults:[],
      showPopup:false,
      showDealerPopup: false,
      showSmallRecoPopup: false,
      showSmallVidPopup: false,
      showVidPopup:false,
      video:'https://www.youtube.com/embed/JXZgKnKs48M',
      vidToShow:[],
      vidToShowVA:'',
      vidForHD:[],
      recoForHD:[],
      recoToRender:[],
      recoDetails:[],
      recoObj:[],
      objectToRender:[],
      hotReco:[
        {
          id:'494907',
          url:'http://organicmusic.jp/?pid=153051478',
          urlShop:'http://www.organicmusic.jp/',
          dealrid:'15'
        },
        {
          id:'4459551',
          url:'http://organicmusic.jp/?pid=153995437',
          urlShop:'http://www.organicmusic.jp/',
          dealrid:'15'
        },
        {
          id:'242668',
          url:'',
          urlShop:'https://www.ella-records.com/',
          dealrid:'14'
        },
        {
          id:'10724877',
          url:'https://www.discogs.com/sell/item/1011486378',
          urlShop:'https://www.ella-records.com/',
          dealrid:'14'
        }
      ],
      dealersDb:[
            {
                name: ['heartbeat','vinyl'],
                location: 'paris',
                address: '26 Rue Godefroy Cavaignac, Paris, France',
                open: 'from 12 pm to 8pm',
                holiday: ', everyday except Sunday',
                description:"Heartbeat Vinyl is a very cosy shop, offering a wide range of music. You'll find Jazz, Disco, House, Techno, Rock, Ambient, Soul, Funk, etc... One thing is sure, you can expect quality music from the owner Melik!",
                url: 'http://www.heartbeatvinyl.com/',
                logo: LogoHeartbeat,
                genre: ['jazz','rock','disco','funk','house','japanese','techno','ambient','soul','progressive','prog','boogie','electro','hiphop','rap','hip','hop','rhythm','&','blues'],
                id:'1',
                reco:[
                    {
                      id:'7326',
                      url:'',
                      urlShop:'http://www.heartbeatvinyl.com/'
                    },
                    {
                      id:'14752081',
                      url:'',
                      urlShop:'http://www.heartbeatvinyl.com/'
                    },
                    {
                      id:'6184448',
                      url:'',
                      urlShop:'http://www.heartbeatvinyl.com/'
                    },
                    {
                      id:'2026507',
                      url:'',
                      urlShop:'http://www.heartbeatvinyl.com/'
                    },
                    {
                      id:'1096121',
                      url:'',
                      urlShop:'http://www.heartbeatvinyl.com/'
                    }
                    ],
                recoInfo:[],
                video:[{
                    shop:['heartbeat','vinyl'],
                    info:'Heartbeat Vinyl May 30th 2020',
                    url:'https://youtube.com/embed/IPC7bgB-6eM',
                    thumb:'https://img.youtube.com/vi/IPC7bgB-6eM/hqdefault.jpg',
                    date: ['may','30','2020'],
                    keywords:['paris','france','heartbeat','disco','soul','funk','techno','deep','japan','japanese','mariya','takeuchi','plastic','love','war','double','exposure','my','love','is','free','moon','shadow','labelle','patti','cotonete','poncet','romain','layla','sergi','rezza','tom','moulton','yamashita','tatsuro']
                }]
            },
            {
                name: ['love','vinyl'],
                location: 'london',
                address: '5 Pearson Street, London, UK',
                open: 'from 11:30 am to 6:30 pm',
                holiday: ', everyday except Monday',
                description:"LoveVinyl, located in the east of London. A warm welcome & personal service to every customer. Buy & sell every musical genre.",
                url: 'https://www.lovevinyl.london/',
                logo: LogoLoveVinyl,
                genre: ['jazz','rock','disco','funk','house','techno','soul','progressive','prog','boogie','electro','hiphop','rap','hip','hop','rhythm','&','blues','r&b'],
                id:'2',
                reco:[
                    {
                      id:'4488271',
                      url:'',
                      urlShop:'https://www.lovevinyl.london/contact/'
                    },
                    {
                      id:'719877',
                      url:'https://www.discogs.com/sell/item/1117982510?ev=bp_det&fbclid=IwAR1gv8bUWWOXHMnEBBrTzLlFgOEQ9ryCkGV-sihcliL111IkWf_KZ55r1rc',
                      urlShop:'https://www.lovevinyl.london/contact/'
                    },
                    {
                      id:'1253332',
                      url:'',
                      urlShop:'https://www.lovevinyl.london/contact/'
                    },
                  ],
                recoInfo:[],
                video:[{
                      shop:['love','vinyl'],
                      info:'Love Vinyl, Jun. 3rd 2020',
                      url:'https://youtube.com/embed/jky9qET-P_E',
                      thumb:'https://img.youtube.com/vi/jky9qET-P_E/hqdefault.jpg',
                      date: ['june','3','2020'],
                      keywords:['love','vinyl','london','uk','great','britain','warehouse','boogie','funk','break','sample','james','brown','payback','mind','power','stone','to','the','bone','aleem','leroy','burgess','get','loose','electro','jimmy','jones',"yesterday's",'yesterday',"'s",'mistakes','rhythm','&','blues','soul','twinight','records']
                  }]
                },
                    {
                      name: ['plug','seven'],
                      location: 'melbourne',
                      address: '313a Smith St Fitzroy, 3065, Vic Australia',
                      open: 'from 11 am to 6pm',
                      holiday: ', everyday except Sunday(11am to 5pm)',
                      description:'Plug Seven is a little record store located at 313a Smith Street in Fitzroy, Melbourne. We’ve been digging records for 20 years and travelling the world to do so. There are about 5000 LPs and 12″s in the shop and around the same in 7"s. We’re all about original Jazz pressings, Soul, R&B, Jamaican 45s, Punk, Prog, Psych, Boogie, Roots Rocksteady Reggae, 90s/80s Rap, Latin, Afro, Brazilian, Boogaloo, Calypso. Most of our records are sourced from digging trips to the USA, Japan, South America, Africa & the Caribbean. You can also find a large cross section of New LPs & 7s.',
                      url: 'https://plugseven.com/',
                      logo: LogoPlugSeven,
                      genre: ['jazz','soul','r&b','jamaican','45s','50s','punk','rock','prog','progressive','psychedelic',"psy","psych",'underground','disco','boogie','roots','rocksteady','reggae','90s','hip','hop','hiphop','80s','latin','african','heat','brazilian','brazil','boogaloo','calypso','caribbean'],
                      id:'3',
                      reco:[
                          {
                            id:'11739194',
                            url:'https://plugseven.com/product/freddie-mcgregor-bobby-bobylon/',
                            urlShop:'https://plugseven.com/'
                          },
                          {
                            id:'15348599',
                            url:'https://www.discogs.com/sell/item/1111877673',
                            urlShop:'https://plugseven.com/'
                          },
                          {
                            id:'597386',
                            url:'',
                            urlShop:'https://plugseven.com/'
                          },
                          {
                            id:'14834919',
                            url:'https://plugseven.com/product/horatio-luna-yes-doctor/',
                            urlShop:'https://plugseven.com/'
                          },
                          {
                            id:'14765115',
                            url:'https://plugseven.com/product/v-a-seven-wonders-new-movements-in-australian-jazz-and-soul/',
                            urlShop:'https://plugseven.com/'
                          }
                          ],
                      recoInfo:[],
                      video:[{
                          shop:['plug','seven'],
                          info:'Plug Seven June 14th 2020',
                          url:'https://youtube.com/embed/U_nm3njzDLc',
                          thumb:'https://img.youtube.com/vi/U_nm3njzDLc/hqdefault.jpg',
                          date: ['june','14','2020'],
                          keywords:['plug','seven','australia','melbourne','horatio','luna','yes','doctor','wvr','bvby','war','baby','custard','shoulder','barney','mcall','rita','satch','sweet','water','the','goats','typical','american','freddie','mcgregor','bandulo','lord','kitchener','dr','kitch','house','deep','jazzy','broken','beat','12','australia','melbourne','jazz','seven','wonders','LP','plug','80s','aor','hip','hop','90s','bay','area','oakland','rap','bobby','bobylon','studio','one','reissue','reggae','rocksteady','jamaica','vinyl','inch','calypso','60s','compilation','funk','soul']
                      }]
                  },
                  {
                    name: ['listen!','record','store'],
                    location: 'paris',
                    address: '43 Rue de la Folie Méricourt, 75011 Paris, France',
                    open: 'from 1 pm to 7pm (Saturday from 2pm to 8pm)',
                    holiday: ', everyday except Sunday/Monday.',
                    description:"Listen! Record Store is a new shop launched by Thomas from French Attack. It's a must go for any digger travelling to Paris. You can follow Thomas' recommends without hesitation for anything rare groove, Japanese or library, or anything good music actually!",
                    url: 'https://www.listenrecordstore.com/',
                    logo: LogoListen,
                    genre: ['pop','rock','synth','jazz','funk','fusion','prog','world','arab','arabic','middle','east','japan','rare','grooves','groove','afro','tropical','70s','80s','90s','rap','hip','hop','ambient','japanese','library','psy','soul','electronic'],
                    id:'4',
                    reco:[
                      {
                        id:'14086280',
                        url:'https://www.listenrecordstore.com/product/jacky-giordano-philopsis-dd-05-ln-01',
                        urlShop:'https://www.listenrecordstore.com/contact'
                        },
                        {
                          id:'1815361',
                          url:'https://www.listenrecordstore.com/product/the-heshoo-beshoo-group-armitage-road-columbia-c-062-80842',
                          urlShop:'https://www.listenrecordstore.com/contact'
                        },
                        {
                          id:'3662270',
                          url:'',
                          urlShop:'https://www.listenrecordstore.com/contact'
                        },
                        {
                          id:'7973356',
                          url:'',
                          urlShop:'https://www.listenrecordstore.com/contact'
                        }
                        ],
                    recoInfo:[],
                    video:[{
                        shop:['listen!','record','store'],
                        info:'Listen! Record Store June 22 2020',
                        url:'https://youtube.com/embed/5rujDBSpvuI',
                        thumb:'https://img.youtube.com/vi/5rujDBSpvuI/hqdefault.jpg',
                        date: ['june','22','2020'],
                        keywords:['paris','france','listen','listen!','heshoo','beshoo','group','the','armitage','road','south','africa','jazz','funk','philopsis','yan',"d'ys",'jacky','giordano','ambient','70s','reissue','cobra','ost','soundtrack','anime','yuji','ohno','kentaro','haneda','disco','japan','japanese','space','abdel','halim','hafez','ammar','el','sheriyi','own','style','arabic','middle','east','middle-east','egypt','groove','rare']
                    }]
                },
                {
                  name: ['fernando','rey'],
                  location: 'United Kingdom',
                  address: 'Individual Dealer (Online)',
                  open: '',
                  holiday: '',
                  description:"Fernando Rey is one of the best individual dealer we have met so far. Dealing with Japanese, ambient/experimental, Balearic, house, techno, disco, soul, jazz, funk and more. Located in the UK, he also DJ regularly for NTS radio show.",
                  url: 'https://www.discogs.com/seller/fernandorey',
                  logo: LogoFernandoRey,
                  genre: ['pop','rock','synth','jazz','funk','fusion','prog','world','japan','rare','grooves','groove','soul','techno','house','balearic','experimental','70s','80s','90s','ambient','japanese','library','psy','new','age','new-age','electronic'],
                  id:'5',
                  reco:[
                    {
                      id:'4238391',
                      url:'https://www.discogs.com/sell/item/1066469032',
                      urlShop:'https://www.discogs.com/seller/fernandorey'
                    },
                    {
                        id:'819661',
                        url:'',
                        urlShop:'https://www.discogs.com/seller/fernandorey'
                    },
                    {
                      id:'7769203',
                      url:'',
                      urlShop:'https://www.discogs.com/seller/fernandorey'
                      }
                      ],
                  recoInfo:[],
                  video:[{
                      shop:['fernando','rey'],
                      info:'Fernando Rey June 29 2020',
                      url:'https://youtube.com/embed/SNSFiFJf0YQ',
                      thumb:'https://img.youtube.com/vi/SNSFiFJf0YQ/hqdefault.jpg',
                      date: ['june','29','2020'],
                      keywords:['uk','united','kingdom','fernando','rey','individual','dealer','hiroshi','yoshimura','air','in','resort','shiseido','promo','ambient','japan',"japanese",'80s','new-age','new','age','test','pattern','testpattern','apres','midi','hosono','proto-techno','synt','synth-pop','pop','yen','obi','tokyo','masaru','imada','trio','jazz','funk','herbie','hancock','green','caterpillar','kazumi','watanabe','groove','rare']
                  }]
              },
              {
                name: ['pianola','records'],
                location: 'tokyo',
                address: '2-36-13 Daita, Setagaya-ku, Tokyo Japan 155-0033',
                open: 'from 2 pm to 9 pm',
                holiday: ', everyday except Thursday',
                description:"Pianola is your necessary pit stop in Tokyo for anything Experimental, Ambient, World, Avant-garde, New-Wave, Noise, Industrial, Folk, and of course, hand-picked by Yohei wonderful Japanese treasures! The owner Yohei will always welcome you nicely, and guide you through his Ali Baba's cave. be prepared for a true musical voyage!",
                url: 'https://pianola-records.com/',
                logo: LogoPianola,
                genre: ['synth','synth pop','jazz','free jazz','fusion','prog','world','japan','rare','grooves','groove','experimental','avant','garde','avant-garde','balearic','70s','80s','90s','ambient','japanese','library','psy','new','age','new-age','electronic','new wave','wave','noise','industrial'],
                id:'6',
                reco:[
                  {
                    id:'1',
                    url:'https://conatala.com/catalog/conatala-002/',
                    urlShop:'https://pianola-records.com/',
                    dealrid:'6',
                    thumbnail: CoverWSAsagao,
                    recoDetails:{
                      dealridName:['pianola','records'],
                      title: 'Asagao',
                      artist: 'World Standard',
                      thumbnail: CoverWSAsagao,
                      link: 'https://conatala.com/catalog/conatala-002/',
                      id:'1',
                      country:'Japan',
                      date:'2020',
                      tracklist:[
                        {
                            "duration": "",
                            "position": "A1",
                            "title": "「麦秋」: Wheat Harvest",
                            "type_": "track"
                        },
                        {
                            "duration": "",
                            "position": "A2",
                            "title": "夜会（ソワレ）」: Soiree",
                            "type_": "track"
                        },
                        {
                          "duration": "",
                          "position": "A3",
                          "title": "「西は西」: West is West",
                          "type_": "track"
                      },
                      {
                        "duration": "",
                        "position": "A4",
                        "title": "「プリマ」: Prima",
                        "type_": "track"
                    },
                    {
                      "duration": "",
                      "position": "A5",
                      "title": "「カナリヤ」: Canary",
                      "type_": "track"
                  },
                  {
                    "duration": "",
                    "position": "B1",
                    "title": "「苺」: Strawberry",
                    "type_": "track"
                },
                {
                  "duration": "",
                  "position": "B2",
                  "title": "「砂の水」: Sand Water",
                  "type_": "track"
                },
                {
                  "duration": "",
                  "position": "B3",
                  "title": "「森」: Forest",
                  "type_": "track"
                },
                {
                  "duration": "",
                  "position": "B4",
                  "title": "「曲芸」: Acrobatics",
                  "type_": "track"
                },
                {
                  "duration": "",
                  "position": "B5",
                  "title": "「「帽子とステッキ」: Hat and Walking Stick",
                  "type_": "track"
                },
                {
                  "duration": "",
                  "position": "B6",
                  "title": "「ハンカチで汗を拭え」: Wipe Sweat With a Handkerchief",
                  "type_": "track"
                }
                    ],
                      formats:[{descriptions:['LP']}],
                      styles:['Folk', 'Ambient'],
                      catnb:'conatala-002',
                      label:'Conatala',
                      genre:['Folk', 'Ambient']
                    }
                    },
                  {
                      id:'1675144',
                      url:'',
                      urlShop:'https://pianola-records.com/'
                  },
                  {
                    id:'2384512',
                    url:'',
                    urlShop:'https://pianola-records.com/'
                    },
                    {
                      id:'14943233',
                      url:'https://conatala.com/catalog/conatala-001/',
                      urlShop:'https://pianola-records.com/'
                      },
                      {
                        id:'2',
                        url:'',
                        urlShop:'https://pianola-records.com/',
                        recoDetails:{
                          dealridName:['pianola','records'],
                          title: 'Sound Source',
                          artist: 'The Pitchshifters',
                          thumbnail: CoverPitchshifters,
                          link: 'https://pianola-records.com/',
                          id:'2',
                          country:'Japan',
                          date:'2012',
                          tracklist:[
                            {
                                "duration": "",
                                "position": "A1",
                                "title": "Charr",
                                "type_": "track"
                            },
                            {
                                "duration": "",
                                "position": "A2",
                                "title": "Court",
                                "type_": "track"
                            },
                            {
                              "duration": "",
                              "position": "B1",
                              "title": "Omohide",
                              "type_": "track"
                          }
                        ],
                          formats:[{descriptions:['7 inch']}],
                          styles:['Experimental', 'Ambient'],
                          catnb:'PAX001',
                          label:'Pax Records',
                          genre:['Experimental', 'Ambient']
                        }
                        }
                    ],
                recoInfo:[],
                video:[{
                    shop:['pianola','records'],
                    info:'Pianola Records July 6th 2020',
                    url:'https://youtube.com/embed/jVT1jJu7Yew',
                    thumb:'https://img.youtube.com/vi/jVT1jJu7Yew/hqdefault.jpg',
                    date: ['july','6','2020'],
                    keywords:['japan','tokyo','shimokitazawa','pianola','conatala','records','japanese','pale','cocoon','ambient','reissue','experimental','toyama','world','standard','demo',"tape",'unreleased','new-age','new','age','folk','country','acoustic','tra','non-standard','hosono','yuko','matsuzaki','synth','synth-pop','pop','mother','of','pearl','box','private','press','dead','stock','peony','pitchshifters','7inch','sample','360 degree','david','cunningham','faust','dance','cassette','new','wave','new-wave']
                }]
            },
            {
              name: ['all','night','flight'],
              location: 'manchester',
              address: '10 Mealhouse Brow, Stockport, SK1 1JP',
              open: '11pm to 7pm (Sun 12pm to 5pm)',
              holiday: ', everyday except Mon, Tues, Weds.',
              description:"All Night Flight Records is a physical and online select record store dealing in predominately left-field, experimental, electronic and worldwide sounds. New and used records are sold, bought at traded",
              url: 'https://allnightflightrecords.com/',
              logo: LogoANFR,
              genre: ['pop','rock','synth','prog','world','japan','techno','house','balearic','experimental','70s','80s','90s','ambient','japanese','library','psy','new','age','new-age','electronic','diy','free','jazz','avant','garde','wave','post','punk','noise'],
              id:'7',
              reco:[
                {
                  id:'14211076',
                  url:'https://allnightflightrecords.com/collections/highlights/products/a-produce-the-clearing',
                  urlShop:'https://allnightflightrecords.com/',
                },
                {
                    id:'3769407',
                    url:'https://allnightflightrecords.com/products/peter-roos-klimax',
                    urlShop:'https://allnightflightrecords.com/',
                },
                {
                  id:'4126932',
                  url:'',
                  urlShop:'https://allnightflightrecords.com/'
                },
                {
                  id:'2108422',
                  url:'https://allnightflightrecords.com/collections/highlights/products/mixman-antiquities',
                  urlShop:'https://allnightflightrecords.com/',
                },
                {
                  id:'2874974',
                  url:'https://allnightflightrecords.com/collections/highlights/products/h-n-a-s-kuttel-im-frost',
                  urlShop:'https://allnightflightrecords.com/',
                }

                  ],
              recoInfo:[],
              video:[{
                  shop:['all','night','flight'],
                  info:'All Night Flight Records July 13th',
                  url:'https://youtube.com/embed/0ioULnMUtFU',
                  thumb:'https://img.youtube.com/vi/0ioULnMUtFU/hqdefault.jpg',
                  date: ['july','13','2020'],
                  keywords:['vanity','reissue','uk','united','kingdom','all','night','flight','records','manchester','pinakotheca','infecund','infection','experimental','industrial','avant-garde','punk','avant','garde','ambient','post','diy','japan','japanese','compilation','private','press','electronic','rock','free','a', 'produce','the','clearing','minimal','trans','port','peter','roos','klimax','sweden','wave','synth','pop','new','mixman','antiquities','blakamix','dub','reggae','küttel','im','frost','hnas','dom','germany','80s','90s']
              }]
          },
          {
            name: ['universounds'],
            location: 'tokyo',
            address: 'Tokyo, Suginami-ku, Koenji-minami, 3-46-9 Plaza U202',
            open: 'from 2pm to 7pm',
            holiday: ', everyday except Wednesday',
            description:"Universounds is one of the top Jazz/Rare Groove records shop in Tokyo. Located in the district of Koenji, you'll be welcomed by Ogawa san, the man behind some of best Jazz reissues of the past few years, especially for Japanese Jazz. Expect to find some of the best Jazz & Rare Groove records in Tokyo!",
            url: 'https://www.universounds.net/',
            logo: LogoUniversounds,
            genre: ['spiritual','free','modal','jazz','funk','soul','rare','groove','japanese'],
            id:'8',
            reco:[
                {
                  id:'11705183',
                  url:'',
                  urlShop:'https://www.universounds.net/'
                },
                {
                  id:'10359904',
                  url:'https://www.universounds.net/product/2109',
                  urlShop:'https://www.universounds.net/'
                },
                {
                  id:'7421778',
                  url:'https://www.universounds.net/product/1735',
                  urlShop:'https://www.universounds.net/'
                },
                {
                  id:'1180757',
                  url:'',
                  urlShop:'https://www.universounds.net/'
                },
                {
                  id:'1708508',
                  url:'',
                  urlShop:'https://www.universounds.net/'
                }
                ],
            recoInfo:[],
            video:[{
                shop:['universounds'],
                info:'Universounds July 27th 2020',
                url:'https://youtube.com/embed/T3utMWM7ajo',
                thumb:'https://img.youtube.com/vi/T3utMWM7ajo/hqdefault.jpg',
                date: ['july','27','2020'],
                keywords:['tokyo','japan','universounds','jazz','soul','funk','rare','groove','grooves','spiritual','rock','modal','koenji','jazzman','compilation','japanese','ryo','fukui','scenery','early','summer','sapporo','hokkaido','hiroshi','suzuki','cat','tohru','aizawa','sacrament','sakura','hiromasa','takeru','muraoka','kunimitsu','inaba','jiro','inagaki','romance','reissue','ensemble','al-salaam','al','salaam','sojourner','strata','east','tolliver','black','optimystical','byard','lancaster','free','just','test','palm','france','70s']
            }]
        },
        {
          name: ['rare','groove'],
          location: 'osaka',
          address: '202 Leestracture Nishi-shinsaibashi 1-9-28 Chuou-ku Nishi-shinsaibashi Osaka City 5420086',
          open: 'from 10am to 7pm',
          holiday: ', everyday',
          description:"If you happen to travel to Osaka, you need to visit Rare Groove. Overall one of the best selection of 2nd hands records you will find in Osaka. Rare Japanese music, Ambient, Synth-Pop, House, NuDisco, Balearic, Italo-Disco. Norio, the owner, has a deep knowledge of good music and he'll be very happy to welcome you in his shop and give you some advice to discover Osaka's local talents as well!.",
          url: 'http://www.raregroove.jp/',
          logo: LogoRareGroove,
          genre: ['jazz','funk','soul','rare','groove','japanese','synth','pop','synth-pop','house','nu','disco','ambient','italo','nu-disco','80s','90s','70s','city','osaka','techno','deep','balearic'],
          id:'9',
          reco:[
              {
                id:'1188801',
                url:'https://www.discogs.com/sell/item/1060746386',
                urlShop:'http://www.raregroove.jp/'
              },
              {
                id:'12402195',
                url:'https://raregroove.shop-pro.jp/?pid=148459437',
                urlShop:'http://www.raregroove.jp/'
              },
              {
                id:'9191297',
                url:'https://raregroove.shop-pro.jp/?pid=145167752',
                urlShop:'http://www.raregroove.jp/'
              },
              {
                id:'13011928',
                url:'https://raregroove.shop-pro.jp/?pid=148264693',
                urlShop:'http://www.raregroove.jp/'
              },
              {
                id:'310125',
                url:'https://raregroove.shop-pro.jp/?pid=125149135',
                urlShop:'http://www.raregroove.jp/'
              }
              ],
          recoInfo:[],
          video:[{
              shop:['rare','groove'],
              info:'Rare Groove August 3rd 2020',
              url:'https://youtube.com/embed/znFxxbkEmM8',
              thumb:'https://img.youtube.com/vi/znFxxbkEmM8/hqdefault.jpg',
              date: ['august','3','2020'],
              keywords:['osaka','japan','rare','groove','ambient','hosono','haruomi','ymo','yellow','magic','orchestra','shambala','signal','south','east','asia','em','record','records','7fo','dub','chill','kansai','roy','comanchero','nu','disco','house','mysidian','beach','parasol','treasure','box','just','in','time','miruga','above','clouds','deep','pineapples','come','on','closer','cosmic','paraiso','70s','80s','proto','electronic','balearic']
          }]
      },
      {
        name: ['groove','bunny','records'],
        location: 'jinhua',
        address: 'Records & Barber Wave, 古子城鼓楼里文化创意园A21, Jinhua, China',
        open: 'from 1pm to 8pm',
        holiday: ', everyday',
        description:"Endy, aka Groove Bunny Records, is a center piece of the new emerging music scene in China. Big lover of Soul, Funk, Hiphop, he's also very specialized in 70s/80s asian music, especially Japanese music. Big contributor on Discogs, he sells a lot of records there, and as a private dealer too when he has visitors. If you go to China, around Shanghai, you NEED to contact him!",
        url: 'https://www.discogs.com/seller/groovebunnyrecords',
        logo: LogoGBR,
        genre: ['chinese','pop','synth','jazz','funk','soul','rare','groove','japanese','synth-pop','80s','balearic','70s','urban','china','ambient','new','wave'],
        id:'10',
        reco:[
            {
              id:'14329332',
              url:'https://www.discogs.com/sell/item/1023932040',
              urlShop:'https://www.discogs.com/seller/groovebunnyrecords'
            },
            {
              id:'11429564',
              url:'https://www.discogs.com/sell/item/1137829143',
              urlShop:'https://www.discogs.com/seller/groovebunnyrecords'
            },
            {
              id:'6724745',
              url:'https://www.discogs.com/sell/item/959548123',
              urlShop:'https://www.discogs.com/seller/groovebunnyrecords'
            },
            {
              id:'11717257',
              url:'https://www.discogs.com/sell/item/1075316482',
              urlShop:'https://www.discogs.com/seller/groovebunnyrecords'
            },
            {
              id:'11297655',
              url:'https://www.discogs.com/sell/item/1075305878',
              urlShop:'https://www.discogs.com/seller/groovebunnyrecords'
            }
            ],
        recoInfo:[],
        video:[{
            shop:['groove','bunny','records'],
            info:'Groove Bunny Records August 10th 2020',
            url:'https://youtube.com/embed/kYXN2JRaJKs',
            thumb:'https://img.youtube.com/vi/kYXN2JRaJKs/hqdefault.jpg',
            date: ['august','10','2020'],
            keywords:['china','japan','rare','groove','ambient','bunny','records','ivu','crystal','princess','asia','chinese','synth','pop','synthetiser','experimental','electronic','record','leftfield','jinhua','he','wenbiao','traditional','folk','world','sound','mixer','kay','huang','funk','urban','francis','lai','celia','hiromi','go','soundtrack','ost','japanese','movie','stage','screen','fujimaru','yoshino','be','free','70s','80s','mechanical','session','visual','series','2010s','rock']
        }]
    },
    {
      name: ['ranamusica'],
      location: 'tokyo',
      address: 'online shop',
      open: ' ',
      holiday: ' ',
      description:"Ranamusica is a fantastic online shop run by Ryo. He curates the records sold there and only pick up top quality records. Expect great club music, forgotten Asian treasures, and mix of worlwide Balearic vibes! Definitely check his website!",
      url: 'http://ranamusica.info/',
      logo: LogoRanamusica,
      genre: ['chinese','korean','pop','synth','jazz','funk','soul','rare','groove','japanese','synth-pop','80s','balearic','70s','urban','china','ambient','new','wave','japan','house','electronic','techno','90s','nu','disco','nu-disco'],
      id:'11',
      reco:[
          {
            id:'10932449',
            url:'http://ranamusica.info/?pid=145942946',
            urlShop:'http://ranamusica.info/'
          },
          {
            id:'888725',
            url:'http://ranamusica.info/?pid=142858409',
            urlShop:'http://ranamusica.info/'
          },
          {
            id:'15493752',
            url:'http://ranamusica.info/?pid=152025779',
            urlShop:'http://ranamusica.info/'
          }
          ],
      recoInfo:[],
      video:[{
          shop:['ranamusica'],
          info:'Ranamusica August 17th 2020',
          url:'https://youtube.com/embed/GNVYa1tUG_E',
          thumb:'https://img.youtube.com/vi/GNVYa1tUG_E/hqdefault.jpg',
          date: ['august','17','2020'],
          keywords:['china','japan','rare','groove','ambient','knopha','amoy','house','deep','tribal','japanese','chinese','synth','pop','synthetiser','new','electronic','record','wave','welcome','to','germany','happy','folk','world','year','80s','baff','memory','from','song','wan','myoungk','alone','初雪白夜','korea','korean','guitar','solo','curtis','endy','clique','2010s','regret','sound','eating','music']
      }]
  },
  {
    name: ['nippon','groove'],
    location: 'Netherlands',
    address: 'Private Dealer',
    open: ' ',
    holiday: ' ',
    description:"Nippon Groove is specialized in Japanese Jazz and Rock, but not only. Ted covers a wide spectrum of Japanese music, and also grooves from all around the world. Check his insta (tetoselekto) and Discogs page for more info!",
    url: 'https://www.discogs.com/user/nippongroove',
    logo: LogoNipponGroove,
    genre: ['jazz','rock','folk','japan','japanese','spiritual','funk','psy','psychadelic','rare','groove','urban','boogie','city','pop'],
    id:'12',
    reco:[
        {
          id:'14539820',
          url:'https://www.discogs.com/sell/item/1112198097',
          urlShop:'https://www.discogs.com/seller/nippongroove'
        },
        {
          id:'6447515',
          url:'https://www.discogs.com/sell/item/1109069849',
          urlShop:'https://www.discogs.com/seller/nippongroove'
        },
        {
          id:'2719981',
          url:'https://www.discogs.com/sell/item/1115496411',
          urlShop:'https://www.discogs.com/seller/nippongroove'
        },
        {
          id:'10618605',
          url:'https://www.discogs.com/sell/item/1112375381',
          urlShop:'https://www.discogs.com/seller/nippongroove'
        },
        {
          id:'2220384',
          url:'https://www.discogs.com/sell/item/1115520563',
          urlShop:'https://www.discogs.com/seller/nippongroove'
        }
        ],
    recoInfo:[],
    video:[{
        shop:['nippon','groove'],
        info:'Nippon Groove August 24th 2020',
        url:'https://youtube.com/embed/b9HwrsrEHco',
        thumb:'https://img.youtube.com/vi/b9HwrsrEHco/hqdefault.jpg',
        date: ['august','24','2020'],
        keywords:['japan','rare','groove','jazz','funk','afro','beat','mexico','american','mexican','afrodelia','banbuino','miho','kei','eleven','spiritual','free','rock','kokezaru','iwazaru','monkeys','reissue','japanese','post','bop','isao','suzuki','double','bass','trio','quartet','blow','up','three','blind','mice','happy','end','haruomi','hosono','shigeru','suzuki','eiichi','ohtaki','kazemachi','roman','natsu','nandesu','soft','folk','okabayashi','kaze','wo','atsumete','yano','akiko','ame','no','wednesday','yellow','magic','orchestra','ymo','tatsuro','yamashita','come','along','sparkle','for','you','compilation','california','resort','80s','70s','kadomatsu','toshiki']
    }]
},
{
  name: ['cosmos','records'],
  location: 'toronto',
  address: '607A Queen Street West, Toronto, Ontario,Canada',
  open: 'Everyday, from Noon to 7pm',
  holiday: '8pm sat and 5pm sun',
  description:"Cosmos Records is the shop you don't want to miss if you visit Toronto. Expert in Rare Grooves, this is where you'll find the rare original record you've been after for so many years.",
  url: 'https://www.cosmosrecords.ca/',
  logo: LogoCosmos,
  genre: ['jazz','rock','folk','spiritual','free','r&b','soul','funk','rare','groove','urban','boogie','disco'],
  id:'13',
  reco:[
      {
        id:'2961396',
        url:'',
        urlShop:'https://www.cosmosrecords.ca/'
      },
      {
        id:'2857090',
        url:'',
        urlShop:'https://www.cosmosrecords.ca/'
      },
      {
        id:'4843193',
        url:'',
        urlShop:'https://www.cosmosrecords.ca/'
      },
      {
        id:'4834811',
        url:'',
        urlShop:'https://www.cosmosrecords.ca/'
      },
      {
        id:'10558333',
        url:'',
        urlShop:'https://www.cosmosrecords.ca/'
      }
      ],
  recoInfo:[],
  video:[{
      shop:['cosmos','record'],
      info:'Cosmos Records August 31st 2020',
      url:'https://youtube.com/embed/373S9wn9_jw',
      thumb:'https://img.youtube.com/vi/373S9wn9_jw/hqdefault.jpg',
      date: ['august','31','2020'],
      keywords:['soul','funk','toronto','canada','aki','s.o.u.l.','ohio','mazi','rare','groove','lou','ragland','just','for','being','you','hot','chocolate','thrust','ja ja','mcneal','niles','cleveland','wee','fly','aeroplane','find','me','love','black','unity','trio','free','jazz','abdu','wadud','islam','mosque','machine','whatnauts','lock','door','60s','70s','motown']
  }]
},
{
  name: ['ella','records'],
  location: 'tokyo',
  address: '1-14-10 Nishihara, Shibuya-ku, Tokyo, Japan',
  open: 'Everyday, from 2pm to 8pm',
  holiday: ', except Monday',
  description:"Ella Records is wonderful indy record shop with 2 locations. Offering a broad selection of records, they go from forgotten classics to records so rare you've never seen them before. Especially if you're looking for rare Japanese stuff, you NEED to visit Ella!",
  url: 'https://www.ella-records.com/',
  logo: LogoElla,
  genre: ['jazz','rock','house','hiphop','funk','r&b','soul','japanese','rare','groove','urban','boogie','disco','new-age','ambient','synth-pop'],
  id:'14',
  reco:[
      {
        id:'10724877',
        url:'https://www.discogs.com/sell/item/1011486378',
        urlShop:'https://www.ella-records.com/'
      },
      {
        id:'242668',
        url:'',
        urlShop:'https://www.ella-records.com/'
      },
      {
        id:'495653',
        url:'',
        urlShop:'https://www.ella-records.com/'
      },
      {
        id:'8781033',
        url:'',
        urlShop:'https://www.ella-records.com/'
      },
      {
        id:'5899558',
        url:'',
        urlShop:'https://www.ella-records.com/'
      }
      ],
  recoInfo:[],
  video:[{
      shop:['ella','record'],
      info:'Ella Records September 24th 2020',
      url:'https://youtube.com/embed/E8PQo44ZRMY',
      thumb:'https://img.youtube.com/vi/E8PQo44ZRMY/hqdefault.jpg',
      date: ['september','24','2020'],
      keywords:['hiphop','pete','rock','cl','smooth','main','ingredient','get','physical','rap','boom','bap','90s','sample','school','steve','hiett','road','beach','pool','side','down','ambient','balearic','british','photographer','visual','guitar','synth','nina','simone','just','in','time','jazz','piano','vocal','live','europe','before','sunset','movie','drive','soundtrack','kavinsky','ost','80s','retro','wave','new','chris','rea','driving','home','christmas','pop','best','70s','ella','records','hatagaya','shimokitazawa','tokyo','japan']
  }]
},
{
  name: ['organic','music'],
  location: 'tokyo',
  address: '4-32-17 Shimoigusa, Suginami-ku, Tokyo, Japan',
  open: 'Everyday, from 3pm to 9pm',
  holiday: ', except Thursday',
  description:"Organic Music is one of the most respected record shop in Japan. Ran by Chee Shimizu who has been leading the wave for the discovery of the Japanese music scene worldwide, Organic Music offers a wide range of genres, often very niche but always top-quality items, selected by Chee Himself. If you go to Tokyo you HAVE to go there and meet Chee!",
  url: 'http://www.organicmusic.jp/',
  logo: LogoOrganicMusic,
  genre: ['jazz','experimental','avant-garde','traditional','folk','concrete','music','classical','noise','japanese','disco','new-age','ambient','synth-pop'],
  id:'15',
  reco:[
      {
        id:'494907',
        url:'http://organicmusic.jp/?pid=153051478',
        urlShop:'http://www.organicmusic.jp/'
      },
      {
        id:'4459551',
        url:'http://organicmusic.jp/?pid=153995437',
        urlShop:'http://www.organicmusic.jp/'
      },
      {
        id:'3856926',
        url:'',
        urlShop:'http://www.organicmusic.jp/'
      },
      {
        id:'8163085',
        url:'http://organicmusic.jp/?pid=153769289',
        urlShop:'http://www.organicmusic.jp/'
      },
      {
        id:'6718085',
        url:'http://organicmusic.jp/?pid=153273735',
        urlShop:'http://www.organicmusic.jp/'
      }
      ],
  recoInfo:[],
  video:[{
      shop:['organic','music'],
      info:'Organic Music October 8th 2020',
      url:'https://youtube.com/embed/NFfeyhWwkLQ',
      thumb:'https://img.youtube.com/vi/NFfeyhWwkLQ/hqdefault.jpg',
      date: ['october','8','2020'],
      keywords:['japan','chee','shimizu','organic','music','experimental','concrete','rene','aubry','still','waters','ballet','keeping','ambient','new','age','jean','schwarz','80s','avant','garde','ransom','wilson','flute','jazz','steve','reich','becker','frank','classical','composer','stonehenge','toru','takemitsu','works','kwaidan','movie','ost','water','electronic','ono','matsuo','promo','yuragi','surround','ohno','tohru','miyashita','susumu','koto','shakuhachi','biwa','contemporary','modern','folk','traditional','70s','astro','boy','60s']
  }]
}
                    ],
      hotDealers:[
        {
          name: ['organic','music'],
          location: 'tokyo',
          address: '4-32-17 Shimoigusa, Suginami-ku, Tokyo, Japan',
          open: 'Everyday, from 3pm to 9pm',
          holiday: ', except Thursday',
          description:"Organic Music is one of the most respected record shop in Japan. Ran by Chee Shimizu who has been leading the wave for the discovery of the Japanese music scene worldwide, Organic Music offers a wide range of genres, often very niche but always top-quality items, selected by Chee Himself. If you go to Tokyo you HAVE to go there and meet Chee!",
          url: 'http://www.organicmusic.jp/',
          logo: LogoOrganicMusic,
          genre: ['jazz','experimental','avant-garde','traditional','folk','concrete','music','classical','noise','japanese','disco','new-age','ambient','synth-pop'],
          id:'15',
          reco:[
              {
                id:'494907',
                url:'http://organicmusic.jp/?pid=153051478',
                urlShop:'http://www.organicmusic.jp/'
              },
              {
                id:'4459551',
                url:'http://organicmusic.jp/?pid=153995437',
                urlShop:'http://www.organicmusic.jp/'
              },
              {
                id:'3856926',
                url:'',
                urlShop:'http://www.organicmusic.jp/'
              },
              {
                id:'8163085',
                url:'http://organicmusic.jp/?pid=153769289',
                urlShop:'http://www.organicmusic.jp/'
              },
              {
                id:'6718085',
                url:'http://organicmusic.jp/?pid=153273735',
                urlShop:'http://www.organicmusic.jp/'
              }
              ],
          recoInfo:[],
          video:[{
              shop:['organic','music'],
              info:'Organic Music October 8th 2020',
              url:'https://youtube.com/embed/NFfeyhWwkLQ',
              thumb:'https://img.youtube.com/vi/NFfeyhWwkLQ/hqdefault.jpg',
              date: ['october','8','2020'],
              keywords:['japan','chee','shimizu','organic','music','experimental','concrete','rene','aubry','still','waters','ballet','keeping','ambient','new','age','jean','schwarz','80s','avant','garde','ransom','wilson','flute','jazz','steve','reich','becker','frank','classical','composer','stonehenge','toru','takemitsu','works','kwaidan','movie','ost','water','electronic','ono','matsuo','promo','yuragi','surround','ohno','tohru','miyashita','susumu','koto','shakuhachi','biwa','contemporary','modern','folk','traditional','70s','astro','boy','60s']
          }]
        },
        {
          name: ['ella','records'],
          location: 'tokyo',
          address: '1-14-10 Nishihara, Shibuya-ku, Tokyo, Japan',
          open: 'Everyday, from 2pm to 8pm',
          holiday: ', except Monday',
          description:"Ella Records is wonderful indy record shop with 2 locations. Offering a broad selection of records, they go from forgotten classics to records so rare you've never seen them before. Especially if you're looking for rare Japanese stuff, you NEED to visit Ella!",
          url: 'https://www.ella-records.com/',
          logo: LogoElla,
          genre: ['jazz','rock','house','hiphop','funk','r&b','soul','japanese','rare','groove','urban','boogie','disco','new-age','ambient','synth-pop'],
          id:'14',
          reco:[
              {
                id:'10724877',
                url:'https://www.discogs.com/sell/item/1011486378',
                urlShop:'https://www.ella-records.com/'
              },
              {
                id:'242668',
                url:'',
                urlShop:'https://www.ella-records.com/'
              },
              {
                id:'495653',
                url:'',
                urlShop:'https://www.ella-records.com/'
              },
              {
                id:'8781033',
                url:'',
                urlShop:'https://www.ella-records.com/'
              },
              {
                id:'5899558',
                url:'',
                urlShop:'https://www.ella-records.com/'
              }
              ],
          recoInfo:[],
          video:[{
              shop:['ella','record'],
              info:'Ella Records September 24th 2020',
              url:'https://youtube.com/embed/E8PQo44ZRMY',
              thumb:'https://img.youtube.com/vi/E8PQo44ZRMY/hqdefault.jpg',
              date: ['september','24','2020'],
              keywords:['hiphop','pete','rock','cl','smooth','main','ingredient','get','physical','rap','boom','bap','90s','sample','school','steve','hiett','road','beach','pool','side','down','ambient','balearic','british','photographer','visual','guitar','synth','nina','simone','just','in','time','jazz','piano','vocal','live','europe','before','sunset','movie','drive','soundtrack','kavinsky','ost','80s','retro','wave','new','chris','rea','driving','home','christmas','pop','best','70s','ella','records','hatagaya','shimokitazawa','tokyo','japan']
          }]
        },
        {
          name: ['cosmos','records'],
          location: 'toronto',
          address: '607A Queen Street West, Toronto, Ontario,Canada',
          open: 'Everyday, from Noon to 7pm',
          holiday: '8pm sat and 5pm sun',
          description:"Cosmos Records is the shop you don't want to miss if you visit Toronto. Expert in Rare Grooves, this is where you'll find the rare original record you've been after for so many years.",
          url: 'https://www.cosmosrecords.ca/',
          logo: LogoCosmos,
          genre: ['jazz','rock','folk','spiritual','free','r&b','soul','funk','rare','groove','urban','boogie','disco'],
          id:'13',
          reco:[
              {
                id:'2961396',
                url:'',
                urlShop:'https://www.cosmosrecords.ca/'
              },
              {
                id:'2857090',
                url:'',
                urlShop:'https://www.cosmosrecords.ca/'
              },
              {
                id:'4843193',
                url:'',
                urlShop:'https://www.cosmosrecords.ca/'
              },
              {
                id:'4834811',
                url:'',
                urlShop:'https://www.cosmosrecords.ca/'
              },
              {
                id:'10558333',
                url:'',
                urlShop:'https://www.cosmosrecords.ca/'
              }
              ],
          recoInfo:[],
          video:[{
              shop:['cosmos','record'],
              info:'Cosmos Records August 31st 2020',
              url:'https://youtube.com/embed/373S9wn9_jw',
              thumb:'https://img.youtube.com/vi/373S9wn9_jw/hqdefault.jpg',
              date: ['august','31','2020'],
              keywords:['soul','funk','toronto','canada','aki','s.o.u.l.','ohio','mazi','rare','groove','lou','ragland','just','for','being','you','hot','chocolate','thrust','ja ja','mcneal','niles','cleveland','wee','fly','aeroplane','find','me','love','black','unity','trio','free','jazz','abdu','wadud','islam','mosque','machine','whatnauts','lock','door','60s','70s','motown']
          }]
        },
        {
          name: ['nippon','groove'],
          location: 'Netherlands',
          address: 'Private Dealer',
          open: ' ',
          holiday: ' ',
          description:"Nippon Groove is specialized in Japanese Jazz and Rock, but not only. Ted covers a wide spectrum of Japanese music, and also grooves from all around the world. Check his insta (tetoselekto) and Discogs page for more info!",
          url: 'https://www.discogs.com/user/nippongroove',
          logo: LogoNipponGroove,
          genre: ['jazz','rock','folk','japan','japanese','spiritual','funk','psy','psychadelic','rare','groove','urban','boogie','city','pop'],
          id:'12',
          reco:[
              {
                id:'14539820',
                url:'https://www.discogs.com/sell/item/1112198097',
                urlShop:'https://www.discogs.com/seller/nippongroove'
              },
              {
                id:'6447515',
                url:'https://www.discogs.com/sell/item/1109069849',
                urlShop:'https://www.discogs.com/seller/nippongroove'
              },
              {
                id:'2719981',
                url:'https://www.discogs.com/sell/item/1115496411',
                urlShop:'https://www.discogs.com/seller/nippongroove'
              },
              {
                id:'10618605',
                url:'https://www.discogs.com/sell/item/1112375381',
                urlShop:'https://www.discogs.com/seller/nippongroove'
              },
              {
                id:'2220384',
                url:'https://www.discogs.com/sell/item/1115520563',
                urlShop:'https://www.discogs.com/seller/nippongroove'
              }
              ],
          recoInfo:[],
          video:[{
              shop:['nippon','groove'],
              info:'Nippon Groove August 24th 2020',
              url:'https://youtube.com/embed/b9HwrsrEHco',
              thumb:'https://img.youtube.com/vi/b9HwrsrEHco/hqdefault.jpg',
              date: ['august','24','2020'],
              keywords:['japan','rare','groove','jazz','funk','afro','beat','mexico','american','mexican','afrodelia','banbuino','miho','kei','eleven','spiritual','free','rock','kokezaru','iwazaru','monkeys','reissue','japanese','post','bop','isao','suzuki','double','bass','trio','quartet','blow','up','three','blind','mice','happy','end','haruomi','hosono','shigeru','suzuki','eiichi','ohtaki','kazemachi','roman','natsu','nandesu','soft','folk','okabayashi','kaze','wo','atsumete','yano','akiko','ame','no','wednesday','yellow','magic','orchestra','ymo','tatsuro','yamashita','come','along','sparkle','for','you','compilation','california','resort','80s','70s','kadomatsu','toshiki']
          }]
      }
    ]
  }
// Binding for new functions
this.togglePopup=this.togglePopup.bind(this);
this.getIdForHDRR=this.getIdForHDRR.bind(this);
this.search=this.search.bind(this);
this.changeState=this.changeState.bind(this);
this.change=this.change.bind(this);
this.displayVid=this.displayVid.bind(this);
this.searchForVid=this.searchForVid.bind(this);
    
  }

// From here come the functions of Home Component

// this function gets all the records information from Discogs for the records listed as Hot Records

componentDidMount()
  {
    let idToRender=[];
    this.state.hotReco.map(item=>
      {
      let dealerObj=this.state.dealersDb.find(elem=>elem.id===item.dealrid)
      let HRidArray=item.id.split('')
      if(HRidArray.length<3){
        idToRender.push(item.recoDetails)
      }else{
      Discogs.search(item.id).then(response=>
        {
        response.buy=item.url
        response.dealridName=dealerObj.name
        idToRender.push(response);
        this.setState(
          {
            recoObj: idToRender
          }
        );
        
        })}
      })
    }
// this function controls which popup is supposed to be shown

togglePopup(term)
{
if(this.state.objectToRender.length>0){
  this.setState({
    showPopup: !this.state.showPopup,
    objectToRender:[]
  })
}
  let idToRender=[];
  let stringT=term.toString();
  this.state.hotReco.map(elem=>
  {
    if(elem.id===stringT)
    {
      this.state.recoObj.map(record=>
        {
        if(term===record.id)
        {
            record.buy=elem.url
            record.visit=elem.urlShop
            idToRender=[record];
        }
        }
        );
console.log(idToRender)
      this.setState(
        {
          showPopup: !this.state.showPopup,
          objectToRender: idToRender
        }
        );
    }})
  


  // the first part above transforms the term, which is expected to be a number(the id)
  // in a string. Then it looks into the array of ids called hotReco, and if there's a match,
  // it then looks into the recoObj array which contains the detail infos for all Hot Records
  // in the form of objects. If there's a match between the term and one of the record id,
  // it then sets idToRender to an array containing the object for which there was a match.
  // Last, it updates objectToRender with that record, and turn the popup switch on.
  // this part of the function is essentially used by the Hot Record component.
  
  this.state.hotDealers.map(dealer=>
    {
      if(term===dealer.id || stringT===dealer.id)
      {
        idToRender=[dealer];
      
  let recoToFind=idToRender[0].reco;
  recoToFind.map(item=>
    {if(item.id.length<3 && idToRender[0].recoInfo.length<recoToFind.length){
      item.recoDetails.buy=item.url
      item.recoDetails.visit=item.urlShop
      return idToRender[0].recoInfo.push(item.recoDetails)
    }
      Discogs.search(item.id).then(response=>
        { 
          response.buy=item.url
          response.visit=item.urlShop
          if(idToRender[0].recoInfo.length<recoToFind.length)
          {
            idToRender[0].recoInfo.push(response)
          }
          return(this.setState(
            {
              objectToRender: idToRender
            }
            ))
        })
    })}
  })

  this.setState(
    {
    showPopup: !this.state.showPopup
  });
}
// the 2nd part of the toggle function first check if there's a match between the checked id
// and the id of one of the Hot Dealers. If so, it then assigns the recommended records ids of that
// matched dealer to a var called recoToFind. It then goes through this recoToFind array and for each
// id, get the associated record info from Discogs and push them as an object to the recoInfo state of
// Dealer object contained in idToRender. So effectively, recoInfo gets filled with objects containing details about recommended records.
// Once this process is finished, it updates objectToRender to the array containing those objects.
// finally, it turns the popup switch on. This part of the function is used by the Hot Dealer component.


getIdForHDRR(term)
{
  let recoToID=[];
  let vidToID=[];

  this.state.objectToRender[0].recoInfo.map(elem=>
    {
      if(elem.id===term)
      {
        recoToID.push(elem)
        this.setState(
          {
        showSmallRecoPopup: !this.state.showSmallRecoPopup,
        recoForHD: recoToID
          })
      }
    }
    )
// the first part of the function above goes through the recoInfo array contained in the objectToRender (Which is now the clicked on dealer
// of the hot dealers section, with recoInfo containing correct record details info from discogs since at this point, togglePopup has been called).
// it checks if the term provided matches with an id of the record objects contain in recoInfo, and if it does it update the recoForHD with this object,
// and also set the switch for the small pop-up (Which concerns popup triggered for record info) to ON.

this.state.objectToRender[0].video.map(elem=>{
    if(elem.url===term){
      vidToID.push(elem)
      this.setState({
        showSmallVidPopup: !this.state.showSmallVidPopup,
        vidForHD: vidToID
  
      })
    }
  })
// The second part of the function works the same way as first part, but is used for the video popup. The purpose is to identify the clicked-on video and
// and send those detailed information to the Popup component as vidForHD.

}

// From here on come the functions of the dealers component

search(term)
{
    
    let tempArray= [];
    if(term.length<2)
    {
        let tempArray = this.state.dealersDb.filter(dealer => {
            return dealer.name.find(elem=>elem===term[0]) || 
            term[0]===dealer.location || 
            dealer.genre.find(element=>term[0]===element);
            
        });

        this.setState({
            searchResults: tempArray,
        })
                
        
            
    }
    else{
        this.state.dealersDb.map(dealer=>
                    {
                        let counter=0;
                        term.map(item=>
                            {
                        if(dealer.name.find(element=>element===item))
                        {
                            counter=counter+1;
                        }})
                        term.map(item=>
                            {
                        if(item===dealer.location)
                        {
                            counter=counter+1;
                        }})
                        term.map(item=>
                            {
                        if(dealer.genre.find(element=>item===element))
                        {
                            counter=counter+1;
                        }})      
                                if(counter===term.length){
                                tempArray.push(dealer);
                                this.setState(
                                    {
                                        searchResults: tempArray
                                    }
                                    )
                            }
                        })
            }
   
}
// the changeState function takes the term (an id) and check if there's a matching id in the Dealer's DB. If there's,
// it checks the reco array of that matched dealer, and for each record id, it makes a request to the discogs API, get
// details info for each record and store them in idToRender var. each time a record info come, it updates the state of
// objectToRender with an array equal to all the record info returned so far.

changeState(term)
{
    let idToRender=[];
    this.state.dealersDb.map(dealer=>
        {
            if(term===dealer.id)
            {
            idToRender=[dealer];
            }
        }
        );
    let recoToFind=idToRender[0].reco;
    recoToFind.map(item=>
        {if(item.id.length<3 && idToRender[0].recoInfo.length<recoToFind.length){
          item.recoDetails.buy=item.url
          item.recoDetails.visit=item.urlShop
          return idToRender[0].recoInfo.push(item.recoDetails)
        }
            Discogs.search(item.id).then(response=>
                {
                    response.buy=item.url
                    response.visit=item.urlShop
                    if(idToRender[0].recoInfo.length<recoToFind.length)
                    {
                        idToRender[0].recoInfo.push(response)
                    }
                    this.setState(
                        {
                            objectToRender: idToRender
                        }
                        );
                }
                )
        }
        ) 
}

//The change function below works with the DealerDetailsList VideoBar and RecordBar, get the IDs of either the clicked on video 
// or record, store the detail info of said record/video it in the Dealers component and then transmit it down so that the proper popup gets displayed.

change(term)
{
    let idToRender=[];
    let vidToID=[];
    this.state.objectToRender[0].recoInfo.map(item=>
        {
            if(term===item.id)
            {
                idToRender=[item];
                this.setState(
                    {
                        showPopup: !this.state.showPopup,
                        recoToRender: idToRender
                    })
            }
        }
        )
    this.state.objectToRender[0].video.map(elem=>
        {
            if(elem.url===term)
            {
                vidToID.push(elem)
                this.setState(
                    {
                        showVidPopup: !this.state.showVidPopup,
                        vidToShow: vidToID
                    }
                    )
            }
        }
        )
}

// From here come the functions for the video archive section

displayVid(term)
{
    this.setState(
        {
        vidToShowVA: term
        }
    )
}

// this search function is used in the SearBarVideo component. It takes the term entered in the input space, and check if there's
// a match with either a Video's keyword, dealer's name or date. If so, it returns each video with at least 1 match in an array, and
// update the state of searchResults to that array.

searchForVid(term)
{
  let tempArray= [];
  if(term.length<2){
    this.state.dealersDb.map(dealer=>
        {
            dealer.video.map(item=>
                {
                    if(
                        item.keywords.find(element=>element===term[0])||
                        item.name===term[0]||
                        item.date.find(element=>element===term[0])
                        )
                        {
                            tempArray.push(item)
                        }
                }
                )
        }
        )
    this.setState(
        {
        searchResults: tempArray
    }
    )
  }else{
    this.state.dealersDb.map(dealer=>
      {
          dealer.video.map(video=>
            {
          let counter=0;
          term.map(item=>
            {if(video.keywords.find(element=>element===item))
                {counter=counter+1;}
            }
          )
          term.map(item=>
              {if(video.shop.find(element=>element===item))
               {
                counter=counter+1;
               }
            }
          )
          term.map(item=>
              {if(video.date.find(element=>element===item))
                {
                 counter=counter+1;
                }
        }
          );      console.log(counter)
                  if(counter===term.length){
                  tempArray.push(video);
                  }
                }
                )
                }
    )
                  this.setState(
                      {
                          searchResults: tempArray
                      }
                      )
                    }
                  }

  render(){
    return(
<Router>
  <div className="MainAppContain">
<div className="App">
  <div className="ContMenBar">
  <div className="LogoImage">
  <img className= "LogoImgHome" src={Logomrd} height="100" width="100" alt="Logo image"/>
  </div>
  <div className="NavMenuApp"><NavMenu show={this.state.objectToRender} getId={this.changeState} searchResults={this.state.searchResults} search={this.search}/>
</div>
</div>
   <div className="MainPForDisplay">
    <Route path='/' exact component={()=>
    <Home 
    togglePopup={this.togglePopup}
    getIdForHDRR={this.getIdForHDRR}
    state={this.state}
    />}/>
    <Route path='/Dealers' component={()=>
    <Dealers
    search={this.search}
    changeState={this.changeState}
    change={this.change}
    state={this.state}
    />}/>
    <Route path='/VideoArchives' component={()=>
    <VideoArchives
    displayVid={this.displayVid}
    searchForVid={this.searchForVid}
    state={this.state}
    />}/>
    <Route path='/About' component={AboutUs}/>
    </div>
</div>
</div>
</Router>
);
  }
}


export default App;
