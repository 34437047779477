import React from 'react';
import HotDealers from '../HotDealers/HotDealers';
import HotRecords from '../HotRecords/HotRecords';
import LatestVideo from '../LatestVideo/LatestVideo';
import logoMob from '../App/LogoMRD.png';
import './Home.css';

class Home extends React.Component{

render(){
  console.log(this.props.state.recoObj)
      return(
<div className="Home">
<img className="LogoImgMob" src={logoMob} alt="logo MRD"/>
<div className="WelContain">
<div className="Welcome">

   <div>WELCOME!</div>
   <br/>My Record Dealer was built to share the love of music through the voice of the people
     who go out there and dig treasures for us to enjoy: Our favorite record dealers! Shop owners or simple resellers,
     they always have interesting stories about records, and our mission is to give them a voice here so they can share
     those stories with us, and introduce their latest musical gems!

</div>
</div>

 <div className="LatestVid">

   <LatestVideo/>
 
 </div>

 <div className="HotDealers">
   <HotDealers 
   hotDealers={this.props.state.hotDealers}
   objectTR={this.props.state.objectToRender} 
   vidForHD={this.props.state.vidForHD}
   recoForHD={this.props.state.recoForHD} 
   showPopup={this.props.state.showPopup} 
   showSRP={this.props.state.showSmallRecoPopup} 
   showSVP={this.props.state.showSmallVidPopup} 
   gifHDRR={this.props.getIdForHDRR} 
   togglePopup={this.props.togglePopup}
 />
 </div>

 <div className="HotRecords">

   <HotRecords
   otr={this.props.state.objectToRender}
   hot={this.props.state.recoObj}
   showPopup={this.props.state.showPopup} 
   toggle={this.props.togglePopup} 
   />

 </div>

</div>

  );
    }
  }
  
  export default Home;