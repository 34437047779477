import React from 'react';
import './VidToShow.css';

class VidToShow extends React.Component{

    render()
    {
        if(!this.props.vidToShow)
        {
            return(
            <div>
                <iframe className="VidToShow" width="500" height="300" src={this.props.defaultVid} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> 
            </div>)
        }
    
        return(
        <div>
            <iframe className="VidToShow" width="500" height="300" src={this.props.vidToShow} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        );
    }
}

export default VidToShow;