import React from 'react';
import VidToShow from '../VidToShow/VidToShow';
import VideoBar from '../VideoBar/VideoBar';
import './LatestVideo.css';

class LatestVideo extends React.Component{
    constructor(props){
        super(props);
// this part contains all the Latest Videos data. Those are choose manually and copy pasted here, as the list of Latest videos to display is decided
// by the direction of the platform, and not solely by the production date.

        this.state={
            vidToShow:'',
            vidBar:['Zg1aJ0fhF2I','_C9ZNqNXODk','Zg1aJ0fhF2I','_C9ZNqNXODk'],
            videoSelected:[
                {
                    shop:['organic','music'],
                    info:'Organic Music October 8th 2020',
                    url:'https://youtube.com/embed/NFfeyhWwkLQ',
                    thumb:'https://img.youtube.com/vi/NFfeyhWwkLQ/hqdefault.jpg',
                    date: ['october','8','2020'],
                    keywords:['japan','chee','shimizu','organic','music','experimental','concrete','rene','aubry','still','waters','ballet','keeping','ambient','new','age','jean','schwarz','80s','avant','garde','ransom','wilson','flute','jazz','steve','reich','becker','frank','classical','composer','stonehenge','toru','takemitsu','works','kwaidan','movie','ost','water','electronic','ono','matsuo','promo','yuragi','surround','ohno','tohru','miyashita','susumu','koto','shakuhachi','biwa','contemporary','modern','folk','traditional','70s','astro','boy','60s']
                },
                {
                    shop:['ella','record'],
                    info:'Ella Records September 24th 2020',
                    url:'https://youtube.com/embed/E8PQo44ZRMY',
                    thumb:'https://img.youtube.com/vi/E8PQo44ZRMY/hqdefault.jpg',
                    date: ['september','24','2020'],
                    keywords:['hiphop','pete','rock','cl','smooth','main','ingredient','get','physical','rap','boom','bap','90s','sample','school','steve','hiett','road','beach','pool','side','down','ambient','balearic','british','photographer','visual','guitar','synth','nina','simone','just','in','time','jazz','piano','vocal','live','europe','before','sunset','movie','drive','soundtrack','kavinsky','ost','80s','retro','wave','new','chris','rea','driving','home','christmas','pop','best','70s','ella','records','hatagaya','shimokitazawa','tokyo','japan']
                },
                {
                    shop:['cosmos','record'],
                    info:'Cosmos Records August 31st 2020',
                    url:'https://youtube.com/embed/373S9wn9_jw',
                    thumb:'https://img.youtube.com/vi/373S9wn9_jw/hqdefault.jpg',
                    date: ['august','31','2020'],
                    keywords:['soul','funk','toronto','canada','aki','s.o.u.l.','ohio','mazi','rare','groove','lou','ragland','just','for','being','you','hot','chocolate','thrust','ja ja','mcneal','niles','cleveland','wee','fly','aeroplane','find','me','love','black','unity','trio','free','jazz','abdu','wadud','islam','mosque','machine','whatnauts','lock','door','60s','70s','motown']
                },
                {
                    shop:['nippon','groove'],
                    info:'Nippon Groove August 24th 2020',
                    url:'https://youtube.com/embed/b9HwrsrEHco',
                    thumb:'https://img.youtube.com/vi/b9HwrsrEHco/hqdefault.jpg',
                    date: ['august','24','2020'],
                    keywords:['japan','rare','groove','jazz','funk','afro','beat','mexico','american','mexican','afrodelia','banbuino','miho','kei','eleven','spiritual','free','rock','kokezaru','iwazaru','monkeys','reissue','japanese','post','bop','isao','suzuki','double','bass','trio','quartet','blow','up','three','blind','mice','happy','end','haruomi','hosono','shigeru','suzuki','eiichi','ohtaki','kazemachi','roman','natsu','nandesu','soft','folk','okabayashi','kaze','wo','atsumete','yano','akiko','ame','no','wednesday','yellow','magic','orchestra','ymo','tatsuro','yamashita','come','along','sparkle','for','you','compilation','california','resort','80s','70s','kadomatsu','toshiki']
                }]
        }
        this.show=this.show.bind(this);
    }

    // this show() function is used to update the vidToShow state with the url of the Video whose Thumbnail has been clicked on.

    show(term)
    {
        this.setState(
            {
            vidToShow: term
        })
    }

    render(){
        return(
        <div className="ContainForVids">
            
            <h3 className="Title">
                Latest Videos
            </h3>
            <div className="MainVideo">
                <VidToShow 
                defaultVid={this.state.videoSelected[0].url} 
                vidToShow={this.state.vidToShow}
                />
            </div>
            <div className="ContainVidBar">
                <VideoBar 
                showVid={this.show} 
                vidBar={this.state.videoSelected}/>
            </div>

        </div>
        )
    }


}

export default LatestVideo;

