import React from 'react';
import Video from '../Video/Video';
import './VideoBar.css';

class VideoBar extends React.Component{
    render()
    {
        if(this.props.noResults){
            return(
                <div className="ContainNoResults">
                    <div className="NoRPattern">Video Archives</div>
                    <div className="NoRPattern">No Results</div>
                    </div>
            )
        }
{/* this first if displays the videobar for the research result in the video archive section */}

        if(this.props.videoResults)
        {
            return(
            <div className="VABar">
                {this.props.videoResults.map(item=>
                {
                    let shopUpper=item.shop.map(
                        item=>
                        {
                        let maj=item.charAt(0).toUpperCase()
                        let restLet=item.slice(1,item.length)
                        return(maj.concat(restLet))
                        }
                        )
            
                        let firstUpper=item.date[0].charAt(0).toUpperCase()
                        let restNorm=item.date[0].slice(1,item.date[0].length)
                        let dateUpper=firstUpper.concat(restNorm)
                        let newDate=[dateUpper,item.date[1],item.date[2]]
                    return(
                    <div className="FrameForVid">
                        <div className="ZeVid">
                        <Video 
                        displayVid={this.props.displayVid} 
                        vidForVA={item}
                        />
                        </div>

                    <div className="ConViInfo">
                    <div className="VidTitMini">
                    {shopUpper.join(' ')}
                    </div>
                    <div className="VidDate">
                        {newDate.join(' ')}
                    </div>
                    </div>
                    </div>)
                }
                )
                }
            </div>)
        }

// This part of the function takes care of the video bar rendered in the Dealer Details section, after a Dealer has been clicked on
// in the Dealers section, OR, of the videobar in the Dealer's details popup after a hot dealer has been clicked on.
            else if(this.props.show)
            {
                let showT= typeof this.props.show;

// The first "if" below checks is there's a vidToShow, which means if a video icon has already been clicked on. In which case,
// vidToShow carries these info in order to transmit them to the Video and then Popup component to render the right video popup.

                if(showT==="object" && this.props.vidToShow)
                {
                    return(
                    <div className="VideoBar4DD">
                        <h3 className="DVTitle">
                            Dealer's Videos
                        </h3>
                        <div className="ContVidUnit">
                        {this.props.show.video.map(item=>
                        {
                            return(
                            <div className="VidUnit">  
                            <Video 
                            viddd={item} 
                            change={this.props.change} 
                            vidToShow={this.props.vidToShow} 
                            showVidPop={this.props.showVidPop} 
                            dealr={this.props.show}
                            gifHDRR={this.props.gifHDRR}
                            vidForHD={this.props.vidForHD}
                            showSVP={this.props.showSVP}
                            />
                            </div>
                            )
                        }
                        )
                        }
                        </div>
                    </div>
                    )
                }

                else if(showT==="object")
                {
                    return(
                    <div className="VideoBar">
                        <h3 className="DVTitle">
                            Dealer's Videos
                        </h3>
                        <div className="ContVidUnit">
                        {this.props.show.video.map(item=>
                        {
                            return(
                            <div className="VidUnit">  
                            <Video 
                            vid={item} 
                            change={this.props.change} 
                            vidToShow={this.props.vidToShow} 
                            showVidPop={this.props.showVidPop}
                            dealr={this.props.show}
                            gifHDRR={this.props.gifHDRR}
                            vidForHD={this.props.vidForHD}
                            showSVP={this.props.showSVP}
                            />
                            </div>)
                        }
                        )
                        }
                        </div>
                    </div>
                    )
                }
            }
// this section below is used to render the video bar for the latest video section on the Home page.

        else if(this.props.vidBar)
        {
            return (
            <div className="VidBar">

             {this.props.vidBar.map(item=>
             {
                let shopUpper=item.shop.map(
                    item=>
                    {
                    let maj=item.charAt(0).toUpperCase()
                    let restLet=item.slice(1,item.length)
                    return(maj.concat(restLet))
                    }
                    )
        
                    let firstUpper=item.date[0].charAt(0).toUpperCase()
                    let restNorm=item.date[0].slice(1,item.date[0].length)
                    let dateUpper=firstUpper.concat(restNorm)
                    let newDate=[dateUpper,item.date[1],item.date[2]]
                 return (
                <div className="VidUnitLV">
                    <Video 
                    source={item} 
                    showVid={this.props.showVid}
                    />
                <div className="ConViInfo">
                    <div className="VidTitMini">
                    {shopUpper.join(' ')}
                    </div>
                    <div className="VidDate">
                        {newDate.join(' ')}
                    </div>
                    </div>
                </div>)
              })}

            </div>)
        }

         else if(this.props.isClear){
             return(
             null
    );
}
        
}
}

export default VideoBar;