import React from 'react';
import Dealerslist from '../Dealerslist/Dealerslist';
import './SearchDealersResults.css';

class SearchDealersResults extends React.Component{
    render()
    {
      return(
      <div className="ContainerDL">

        <Dealerslist 
        dealerResults={this.props.data} 
        isClear={true} 
        getId={this.props.getId}
        />

      </div>);
    }
}
    
export default SearchDealersResults;