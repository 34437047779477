import React from 'react';
import './Dealer.css';
import Popup from '../Popup/Popup';

class Dealer extends React.Component{
    constructor(props){
        super(props);
        this.changeID=this.changeID.bind(this);
        this.changeIDforDD=this.changeIDforDD.bind(this);

    }
// this changeID function sends the ID of the clicked on Hot Dealer to the
// togglePopup function of the Home component.

     changeID()
     {
        this.props.togglePopup(this.props.dealr.id)
      }
// this changeIDforDD function sends the ID of the clicked on Dealer to the 
// getId function of the Dealers component.

    changeIDforDD()
    {
      this.props.getId(this.props.dealr.id)
    }
    
    render()
    {
// the first "if" below checks if the request for a dealer component is coming from the Hot Dealer
// Component or not. If it does, then it executes and renders a dealer item, along with a Popup
// that will only render if the Popup switch is ON and if the present dealr object matches
// with the object coming from the dealer that has been clicked on, and conveyed through objectTR.
let titleUpper=this.props.dealr.name.map(
  item=>
  {
  let maj=item.charAt(0).toUpperCase()
  let restLet=item.slice(1,item.length)
  return(maj.concat(restLet))

})      
if(this.props.hotDealers)
      { 
        return(
          <div className="RealDealerH">

            <div className="LogoDealer">
            <img
            onTouchEnd={this.changeID}
            className="LogoDealerImg" 
            src={this.props.dealr.logo} 
            height="55" 
            width="55" 
            alt="logo shop"/>
            </div>
            <h5 className="ShopName">
              {titleUpper.join(' ')}
            </h5>
            <button className="ButDetails" onClick={this.changeID}>
              details
            </button>
            <h5 className="ShopLocation">
              {this.props.dealr.location}
            </h5>


        {this.props.showPopup && this.props.objectTR[0]===this.props.dealr?
        <Popup
          showHD={this.props.objectTR[0]}
          dealr={this.props.dealr}
          vidForHD={this.props.vidForHD}
          recoForHD={this.props.recoForHD} 
          showSRP={this.props.showSRP}
          showSVP={this.props.showSVP}
          gifHDRR={this.props.gifHDRR}
          closePopup={this.changeID}
          
            />
            : null
        }
          
        </div>)
      }

      // this second part of the render function below is used to return a dealer item in case
      // such request comes from the DealerList (and before it, the SearchDealerResults) component.
      else{
       
      return(
      
        <div className="RealDealer">
          <div className="LogoDealer">
            <img
            className="LogoDealerImg"
            onTouchStart={this.changeIDforDD}  
            src={this.props.dealr.logo} 
            height="55" 
            width="55" 
            alt="logo shop"/>
          </div>
          <h5 className="ShopName">
            {titleUpper.join(' ')}
          </h5>
          <button className="ButDetails" onClick={this.changeIDforDD}>
            details
          </button>
          <h5 className="ShopLocation">
            {this.props.dealr.location}
          </h5>

        </div>      
      

            );
      }
    }
  
     
};

export default Dealer;