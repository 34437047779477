import React from 'react';
import Record from '../Record/Record';
import './RecordBar.css';

class RecordBar extends React.Component{


    render()
    {
// This first if in the render function is used to render the record bar that will be displayed in the dealer details after a hot
// dealer has been clicked on.

        if(this.props.gifHDRR)
        {
            return(
            <div className="ContRecBarHD">

                <div className="RecoBarTitle">
                    Dealer's Recommend
                </div>
                <div className="RecordBarHDD">
                    {this.props.showDDRR.recoInfo.map(item=>
                    {
                        return(
                        <div className="ContRecoDD">
                        <Record
                        showDDRR={this.props.showDDRR}
                        recoD={item} 
                        recoForHD={this.props.recoForHD} 
                        showSRP={this.props.showSRP} 
                        gifHDRR={this.props.gifHDRR} 
                        />
                        </div>)
                    })
                    }
                </div>

            </div>);
        }
// The part of the function below is used in the Dealers section, after a dealer has been clicked on, its recoInfo get filled with
// record details, and for each record detail, a record component is produced below.

        else if(this.props.show.recoInfo)
        {
            return(
            <div className="ContRecBar">
                <div className="RecoBarTitleDD">
                    Dealer's Recommend
                </div>
                <div className="RecordBar">
                    {this.props.show.recoInfo.map(item=>
                    {

                        return(
                            <div className="ContRecoDD">
                            <Record
                            dealrInfo={this.props.show} 
                            showPop={this.props.showPop} 
                            recoTR={this.props.recoTR} 
                            change={this.props.change} 
                            recoD={item}
                            />
                            </div>)
                    }
                    )
                    }
                </div>
            </div>
            );
        }
}
}

export default RecordBar;