import React from 'react';
import Dealer from '../Dealer/Dealer';
import './Dealerslist.css';

class Dealerslist extends React.Component{

    render()
    {
// The first part of the render function is used for the landing page of the Dealers section, when no search has been made yet.

        if(this.props.dealerResults.length === 0)
        {
            return(
            <div className="List">
                <h2>Dealers list<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                No Results
                </h2>
            </div>
            )
        }
// The second part of the render function returns a list of dealers. The dealers that are contained in the search results array.
        else if(this.props.isClear)
        {
            return(
            <div className="ContainerDealerslist">

                <div className="List">
                    <h3>
                        Dealers list
                    </h3>
                </div>
                <div className="BoxDealList">
                {this.props.dealerResults.map(dealr=>
                {
                    return(
                    <Dealer 
                    dealr={dealr} 
                    key={dealr.id} 
                    getId={this.props.getId}
                    />
                    );
                }
                )
                }
                </div>

            </div>
        );
    }
    }
}


export default Dealerslist;