import React from 'react';
import Video from '../Video/Video';
import './ShowVideo.css';

class ShowVideo extends React.Component{

    render()
    {
        if(this.props.show.length>0)
        {
            return(
            <div className="ShowMainVideo">
                <div className="MVBack">
                    <Video 
                    showMV={this.props.show}
                    />
                </div>
            </div>
            )
        }
        else
        {
            return(null);
        }

    }
}

export default ShowVideo;