import React from 'react';
import VideoBar from '../VideoBar/VideoBar';
import './SearchVideoResults.css';

class SearchVideoResults extends React.Component{
    render()
    {
      if(this.props.data.length===0){
        return(
          <div className="SVRContainer">
        <VideoBar 
        noResults={true} 
        />
      </div>
        )
      }
      return(
      <div className="SVRContainer">
        <VideoBar 
        displayVid={this.props.displayVid} 
        videoResults={this.props.data} 
        isClear={true} 
        />
      </div>);
    }
}
    
    export default SearchVideoResults;