import React from 'react';
import './NavMenu.css';
import Dealers from '../Dealers/Dealers';
import Home from '../Home/Home';
import VideoArchives from '../VideoArchives/VideoArchives';
import AboutUs from '../AboutUs/AboutUs';
import {Link} from 'react-router-dom';
import Logomrd from '../App/LogoMRD.png';

class NavMenu extends React.Component{
    render(){
        return(
    
<ul className="navigation">
<div className="LogoImageNav">
  <img className= "LogoImgHomeNav" src={Logomrd} height="100" width="100" alt="Logo image"/>
  </div>
    <Link to="/" exact>
    <button className="home button" onClick={<Home/>}>home</button>
    </Link>
    <Link to={{
        pathname:'/Dealers',
        state:{
        dealers: this.props.dealers,
        show:this.props.show,
        getId:this.props.getId,
        searchResults:this.props.searchResults,
        search:this.props.search,
    }}}>
    <button className="dealr button" onClick={<Dealers/>}>dealers</button>
    </Link>
    <Link to="/VideoArchives">
    <button className="videoArchives button" onClick={<VideoArchives/>}>video archives</button>
    </Link>
    <Link to="/About">
    <button className="aboutUs button" onClick={<AboutUs/>}>about us</button>
    </Link>
    

</ul>
)
    }
}

export default NavMenu;
