import React from 'react';
import RecordBar from '../RecordBar/RecordBar';
import VideoBar from '../VideoBar/VideoBar';
import Closebut from './close.png';
import './Popup.css';

class Popup extends React.Component {
    render(){
    
// this part of the render function is used to render a video popup after a video thumbnail is clicked on in the Dealer Details big popup (which
// itself got displayed because a hot dealer was clicked on) or in the Dealer's details after a dealer has been clicked on in the Dealers section.

        if(this.props.vid)
        { 
            return(
            <div className='popup'>

                <div className='InviL' onClick={this.props.closePopup}></div>
                <div className='popup_innerForVid'>
                    <iframe className="Vid4HD" width="100%" height="100%" src={this.props.vid.url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                    </iframe>
                </div>

            </div>
              ); 
        }
// the first "if" is used to check if the request for a popup comes from user clicking on a record thumbnail present in the
// record bar displayed in the Dealer's details (rendered after a hot dealer has been clicked on).
// the second if checks if said record has a tracklist of 15 or less tracks, and if so, return its details in a small popup.

        if(this.props.recoForHD && this.props.showRR)
        {
            let titleUpper1=this.props.showDDRR.name.map(
                item=>
                {
                let maj=item.charAt(0).toUpperCase()
                let restLet=item.slice(1,item.length)
                return(maj.concat(restLet))
                }
                ) 
            if
            (this.props.recoForHD[0].tracklist.length<12)
            {
                return(
                <div className='popup'>
                    <div className='InviL' onClick={this.props.closePopup}></div>
                    <div className="MainContentPopSmall">
                    <div className="ButCloseReco">
                        <img className="CloseStyle" src={Closebut} onTouchStart={this.props.closePopup} alt="closebutton"/>
                    </div>
                        <div className='CovAndInfSmall'>
                            <div className='CoverSmall'>
                                <img 
                                height="140px"
                                width="140px"
                                className="CoverSmallImg"
                                src={this.props.recoForHD[0].thumbnail} 
                                alt="logo record"
                                />
                            </div>
                            <div className="ContainInfoTitSmall">
                                <h4>
                                    {this.props.recoForHD[0].artist}
                                </h4>
                                <h4 className="ProjTitleHD">
                                    {this.props.recoForHD[0].title}
                                </h4>
                                <br/><br/>
                            </div>
                            <div className="ContainFormatISmall">
                                <div className="FormatISmall">
                                    Release:
                                </div>
                                <div className="FormatISmall">
                                    Genre:
                                </div>
                                <div className="FormatISmall">
                                    Format:
                                </div>
                                <div className="FormatISmall">
                                    Label:
                                </div>
                                <div className="FormatISmall">
                                    Cat.:
                                </div>
                                <div className="FormatISmall">
                                    Country:
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].date}
                                </div>
                                {this.props.recoForHD[0].styles ?
                                <div className="ReturnISmall">
                                {this.props.recoForHD[0].styles[0]} {this.props.recoForHD[0].styles[1]}
                            </div>
                                : <div className="ReturnISmall">
                                {this.props.recoForHD[0].genre[0]} {this.props.recoForHD[0].genre[1]}
                            </div>
                                }
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].formats[0].descriptions[0]}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].label}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].catnb}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].country}
                                </div>
                            </div>
                        </div>
                        <div className="ContainTheTrackL">
                            <div className="TracklistTit">
                                Tracklist
                            </div>
                            <div className="TracklistDetails">
                                <h4 className="ContainDet">
                                    {this.props.recoForHD[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="poz">
                                            {item.position}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="pozBig">
                                            {item.position}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.recoForHD[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="tit">
                                            {item.title}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="titBig">
                                            {item.title}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.recoForHD[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="dur">
                                            {item.duration}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="durBig">
                                            {item.duration}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                            </div>
                            {this.props.recoForHD[0].buy==='' ?
        <div className="BuyButtonSection">
        <h3>
        Contact {titleUpper1.join(' ')} if you're interested in <a 
        className="BuyButton" 
        href={this.props.recoForHD[0].visit}
        >
            this record
        </a>
    </h3>
    </div>
        :  <div className="BuyButtonSection">
        <h3>
            Visit {titleUpper1.join(' ')}'s online store to <a 
            className="BuyButton" 
            href={this.props.recoForHD[0].buy}
            >
                 buy this record
            </a>
        </h3>

    </div>
        }
                           
                        </div>
                    </div>
                </div>
              );
            }

// this else below is here for records with a tracklist longer than 11 tracks, so the Popup is slightly bigger.

            else if(this.props.recoForHD[0].tracklist.length>11 && this.props.recoForHD[0].tracklist.length<17)
            {
                return (
                <div className='popup'>
                    <div className='InviL' onClick={this.props.closePopup}></div>
                    <div className="MainContentPopBig">
                    <div className="ButCloseReco">
                    <img className="CloseStyle" src={Closebut} onTouchStart={this.props.closePopup} alt="closebutton"/>                       
                        </div>
                        <div className='CovAndInfBig'>
                            <div className='CoverSmall'>
                                <img
                                height="140px"
                                width="140px"
                                className="CoverBigImg" 
                                src={this.props.recoForHD[0].thumbnail} 
                                alt="logo record"/>
                            </div>
                            <div className="ContainInfoTitBig">
                                <h4>
                                    {this.props.recoForHD[0].artist}
                                </h4>
                                <h4 className="ProjTitleHD">
                                    {this.props.recoForHD[0].title}
                                </h4>
                                <br/><br/>
                            </div>
                            <div className="ContainFormatIBig">
                                <div className="FormatIBig">
                                    Release:
                                </div>
                                <div className="FormatIBig">
                                    Genre:
                                </div>
                                <div className="FormatIBig">
                                    Format:
                                </div>
                                <div className="FormatIBig">
                                    Label:
                                </div>
                                <div className="FormatIBig">
                                    Cat.:
                                </div>
                                <div className="FormatIBig">
                                    Country:
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].date}
                                </div>
                                {this.props.recoForHD[0].styles ?
                                <div className="ReturnISmall">
                                {this.props.recoForHD[0].styles[0]} {this.props.recoForHD[0].styles[1]}
                            </div>
                                : <div className="ReturnISmall">
                                {this.props.recoForHD[0].genre[0]} {this.props.recoForHD[0].genre[1]}
                            </div>
                                }
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].formats[0].descriptions[0]}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].label}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].catnb}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].country}
                                </div>
                            </div>
                        </div>
                        <div className="ContainTheTrackL">
                            <div className="TracklistTit">
                                Tracklist
                            </div>
                            <div className="TracklistDetails">
                                <h4 className="ContainDet">
                                    {this.props.recoForHD[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="poz">
                                            {item.position}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="pozBig">
                                            {item.position}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.recoForHD[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="tit">
                                            {item.title}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="titBig">
                                            {item.title}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.recoForHD[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="dur">
                                            {item.duration}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="durBig">
                                            {item.duration}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                            </div>
                            {this.props.recoForHD[0].buy==='' ?
        <div className="BuyButtonSection">
        <h3>
        Contact {titleUpper1.join(' ')} if you're interested in <a 
        className="BuyButton" 
        href={this.props.recoForHD[0].visit}
        >
            this record
        </a>
    </h3>
    </div>
        :  <div className="BuyButtonSection">
        <h3>
            Visit {titleUpper1.join(' ')}'s online store to <a 
            className="BuyButton" 
            href={this.props.recoForHD[0].buy}
            >
                 buy this record
            </a>
        </h3>

    </div>
        }
                        </div>
                    </div>
                </div>)
            }
            else
            {
                return (
                <div className='popup'>
                    <div className='InviL' onClick={this.props.closePopup}></div>
                    <div className="MainContentPopHuge">
                    <div className="ButCloseReco">
                    <img className="CloseStyle" src={Closebut} onTouchStart={this.props.closePopup} alt="closebutton"/>                       
                        </div>
                        <div className='CovAndInfHuge'>
                            <div className='CoverSmall'>
                                <img
                                height="140px"
                                width="140px"
                                className="CoverBigImg" 
                                src={this.props.recoForHD[0].thumbnail} 
                                alt="logo record"/>
                            </div>
                            <div className="ContainInfoTitHuge">
                                <h4>
                                    {this.props.recoForHD[0].artist}
                                </h4>
                                <h4 className="ProjTitleHD">
                                    {this.props.recoForHD[0].title}
                                </h4>
                                <br/><br/>
                            </div>
                            <div className="ContainFormatIHuge">
                                <div className="FormatIHuge">
                                    Release:
                                </div>
                                <div className="FormatIHuge">
                                    Genre:
                                </div>
                                <div className="FormatIHuge">
                                    Format:
                                </div>
                                <div className="FormatIHuge">
                                    Label:
                                </div>
                                <div className="FormatIHuge">
                                    Cat.:
                                </div>
                                <div className="FormatIHuge">
                                    Country:
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].date}
                                </div>
                                {this.props.recoForHD[0].styles ?
                                <div className="ReturnISmall">
                                {this.props.recoForHD[0].styles[0]} {this.props.recoForHD[0].styles[1]}
                            </div>
                                : <div className="ReturnISmall">
                                {this.props.recoForHD[0].genre[0]} {this.props.recoForHD[0].genre[1]}
                            </div>
                                }
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].formats[0].descriptions[0]}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].label}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].catnb}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.recoForHD[0].country}
                                </div>
                            </div>
                        </div>
                        <div className="ContainTheTrackL">
                            <div className="TracklistTit">
                                Tracklist
                            </div>
                            <div className="TracklistDetailsHuge">
                                <h4 className="ContainDet">
                                    {this.props.recoForHD[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="poz">
                                            {item.position}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="pozHuge">
                                            {item.position}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.recoForHD[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="tit">
                                            {item.title}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="titHuge">
                                            {item.title}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.recoForHD[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="dur">
                                            {item.duration}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="durHuge">
                                            {item.duration}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                            </div>
                            {this.props.recoForHD[0].buy==='' ?
        <div className="BuyButtonSection">
        <h3>
        Contact {titleUpper1.join(' ')} if you're interested in <a 
        className="BuyButton" 
        href={this.props.recoForHD[0].visit}
        >
            this record
        </a>
    </h3>
    </div>
        :  <div className="BuyButtonSection">
        <h3>
            Visit {titleUpper1.join(' ')}'s online store to <a 
            className="BuyButton" 
            href={this.props.recoForHD[0].buy}
            >
                 buy this record
            </a>
        </h3>

    </div>
        }
                        </div>
                    </div>
                </div>)
            }
        }
// The part of the function below is used to render the popup that is expected after a record thumbnail has been clicked on in the 
// dealer's details of the Dealers section.

        else if(this.props.showRR)
        { let titleUpper2=this.props.dealrInfo.name.map(
            item=>
            {
            let maj=item.charAt(0).toUpperCase()
            let restLet=item.slice(1,item.length)
            return(maj.concat(restLet))
            }
            )
            if(this.props.showRR[0].tracklist.length<12)
            {
                return (
                <div className='popup'>
                    <div className='InviL' onClick={this.props.closePopup}></div>
                    <div className="MainContentPopSmall">
                    <div className="ButCloseReco">
                    <img className="CloseStyle" src={Closebut} onTouchStart={this.props.closePopup} alt="closebutton"/> </div>
                        <div className='CovAndInfSmall'>
                            <div className='CoverSmall'>
                                <img
                                height="140px"
                                width="140px"
                                className="CoverSmallImg"
                                src={this.props.showRR[0].thumbnail}
                                alt="logo record"/>
                            </div>
                            <div className="ContainInfoTitSmall">
                                <h4>
                                    {this.props.showRR[0].artist}
                                </h4>
                                <h4 className="ProjTitle">
                                    {this.props.showRR[0].title}
                                </h4>
                                <br/><br/>
                            </div>
                            <div className="ContainFormatISmall">
                                <div className="FormatISmall">
                                    Release:
                                </div>
                                <div className="FormatISmall">
                                    Genre:
                                </div>
                                <div className="FormatISmall">
                                    Format:
                                </div>
                                <div className="FormatISmall">
                                    Label:
                                </div>
                                <div className="FormatISmall">
                                    Cat.:
                                </div>
                                <div className="FormatISmall">
                                    Country:
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showRR[0].date}
                                </div>
                                {this.props.showRR[0].styles ?
                                <div className="ReturnISmall">
                                {this.props.showRR[0].styles[0]} {this.props.showRR[0].styles[1]}
                            </div>
                                : <div className="ReturnISmall">
                                {this.props.showRR[0].genre[0]} {this.props.showRR[0].genre[1]}
                            </div>
                                }
                                <div className="ReturnISmall">
                                    {this.props.showRR[0].formats[0].descriptions[0]}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showRR[0].label}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showRR[0].catnb}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showRR[0].country}
                                </div>
                            </div>
                        </div>
                        <div className="ContainTheTrackL">
                            <div className="TracklistTit">
                                Tracklist
                            </div>
                            <div className="TracklistDetails">
                                <h4 className="ContainDet">
                                    {this.props.showRR[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="poz">
                                            {item.position}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="pozBig">
                                            {item.position}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.showRR[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="tit">
                                            {item.title}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="titBig">
                                            {item.title}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.showRR[0].tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="dur">
                                            {item.duration}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="durBig">
                                            {item.duration}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                            </div>
                            {this.props.showRR[0].buy==='' ?
        <div className="BuyButtonSection">
        <h3>
        Contact {titleUpper2.join(' ')} if you're interested in <a 
        className="BuyButton" 
        href={this.props.showRR[0].visit}
        >
            this record
        </a>
    </h3>
    </div>
        :  <div className="BuyButtonSection">
        <h3>
            Visit {titleUpper2.join(' ')}'s online store to <a 
            className="BuyButton" 
            href={this.props.showRR[0].buy}
            >
                 buy this record
            </a>
        </h3>

    </div>
        }
                        </div>
                    </div>
                </div>
                );
            }
            else if(this.props.showRR[0].tracklist.length>11 && this.props.showRR[0].tracklist.length<17)
            {
                return (
                    <div className='popup'>
                        <div className='InviL' onClick={this.props.closePopup}></div>
                        <div className="MainContentPopBig">
                        <div className="ButCloseReco">
                        <img className="CloseStyle" src={Closebut} onTouchStart={this.props.closePopup} alt="closebutton"/></div>
                            <div className='CovAndInfBig'>
                                <div className='CoverSmall'>
                                    <img 
                                    height="140px"
                                    width="140px"
                                    src={this.props.showRR[0].thumbnail} 
                                    alt="logo record"/>
                                </div>
                                <div className="ContainInfoTitBig">
                                    <h4>
                                        {this.props.showRR[0].artist}
                                    </h4>
                                    <h4 className="ProjTitle">
                                        {this.props.showRR[0].title}
                                    </h4>
                                    <br/><br/>
                                </div>
                                <div className="ContainFormatIBig">
                                <div className="FormatIBig">
                                    Release:
                                </div>
                                <div className="FormatIBig">
                                    Genre:
                                </div>
                                <div className="FormatIBig">
                                    Format:
                                </div>
                                <div className="FormatIBig">
                                    Label:
                                </div>
                                <div className="FormatIBig">
                                    Cat.:
                                </div>
                                <div className="FormatIBig">
                                    Country:
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showRR[0].date}
                                </div>
                                {this.props.showRR[0].styles ?
                                <div className="ReturnISmall">
                                {this.props.showRR[0].styles[0]} {this.props.showRR[0].styles[1]}
                            </div>
                                : <div className="ReturnISmall">
                                {this.props.showRR[0].genre[0]} {this.props.showRR[0].genre[1]}
                            </div>
                                }
                                <div className="ReturnISmall">
                                    {this.props.showRR[0].formats[0].descriptions[0]}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showRR[0].label}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showRR[0].catnb}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showRR[0].country}
                                </div>
                            </div>
                            </div>
                            <div className="ContainTheTrackL">
                                <div className="TracklistTit">
                                    Tracklist
                                </div>
                                <div className="TracklistDetails">
                                    <h4 className="ContainDet">
                                        {this.props.showRR[0].tracklist.map(item=>
                                        {
                                            if(item.title.length<35)
                                        {
                                        return(
                                        <div className="poz">
                                            {item.position}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="pozBig">
                                            {item.position}
                                        </div>
                                            )
                                        }
                                        }
                                        )
                                        }
                                    </h4>
                                    <h4 className="ContainDet">
                                        {this.props.showRR[0].tracklist.map(item=>
                                        {
                                            if(item.title.length<35)
                                        {
                                        return(
                                        <div className="tit">
                                            {item.title}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="titBig">
                                            {item.title}
                                        </div>
                                            )
                                        }
                                        }
                                        )
                                        }
                                    </h4>
                                    <h4 className="ContainDet">
                                        {this.props.showRR[0].tracklist.map(item=>
                                        {
                                            if(item.title.length<35)
                                        {
                                        return(
                                        <div className="dur">
                                            {item.duration}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="durBig">
                                            {item.duration}
                                        </div>
                                            )
                                        }
                                        }
                                        )
                                        }
                                    </h4>
                                </div>
                                {this.props.showRR[0].buy==='' ?
        <div className="BuyButtonSection">
        <h3>
        Contact {titleUpper2.join(' ')} if you're interested in <a 
        className="BuyButton" 
        href={this.props.showRR[0].visit}
        >
            this record
        </a>
    </h3>
    </div>
        :  <div className="BuyButtonSection">
        <h3>
            Visit {titleUpper2.join(' ')}'s online store to <a 
            className="BuyButton" 
            href={this.props.showRR[0].buy}
            >
                 buy this record
            </a>
        </h3>

    </div>
        }
                            </div>
                        </div>
                    </div>
                    );}
                    else
                    {
                        return (
                            <div className='popup'>
                                <div className='InviL' onClick={this.props.closePopup}></div>
                                <div className="MainContentPopHuge">
                                <div className="ButCloseReco">
                                <img className="CloseStyle" src={Closebut} onTouchStart={this.props.closePopup} alt="closebutton"/></div>
                                    <div className='CovAndInfHuge'>
                                        <div className='CoverSmall'>
                                            <img 
                                            height="140px"
                                            width="140px"
                                            src={this.props.showRR[0].thumbnail} 
                                            alt="logo record"/>
                                        </div>
                                        <div className="ContainInfoTitHuge">
                                            <h4>
                                                {this.props.showRR[0].artist}
                                            </h4>
                                            <h4 className="ProjTitle">
                                                {this.props.showRR[0].title}
                                            </h4>
                                            <br/><br/>
                                        </div>
                                        <div className="ContainFormatIHuge">
                                        <div className="FormatIBig">
                                            Release:
                                        </div>
                                        <div className="FormatIBig">
                                            Genre:
                                        </div>
                                        <div className="FormatIBig">
                                            Format:
                                        </div>
                                        <div className="FormatIBig">
                                            Label:
                                        </div>
                                        <div className="FormatIBig">
                                            Cat.:
                                        </div>
                                        <div className="FormatIBig">
                                            Country:
                                        </div>
                                        <div className="ReturnISmall">
                                            {this.props.showRR[0].date}
                                        </div>
                                        {this.props.showRR[0].styles ?
                                        <div className="ReturnISmall">
                                        {this.props.showRR[0].styles[0]} {this.props.showRR[0].styles[1]}
                                    </div>
                                        : <div className="ReturnISmall">
                                        {this.props.showRR[0].genre[0]} {this.props.showRR[0].genre[1]}
                                    </div>
                                        }
                                        <div className="ReturnISmall">
                                            {this.props.showRR[0].formats[0].descriptions[0]}
                                        </div>
                                        <div className="ReturnISmall">
                                            {this.props.showRR[0].label}
                                        </div>
                                        <div className="ReturnISmall">
                                            {this.props.showRR[0].catnb}
                                        </div>
                                        <div className="ReturnISmall">
                                            {this.props.showRR[0].country}
                                        </div>
                                    </div>
                                    </div>
                                    <div className="ContainTheTrackL">
                                        <div className="TracklistTit">
                                            Tracklist
                                        </div>
                                        <div className="TracklistDetailsHuge">
                                            <h4 className="ContainDet">
                                                {this.props.showRR[0].tracklist.map(item=>
                                                {
                                                    if(item.title.length<35)
                                                {
                                                return(
                                                <div className="poz">
                                                    {item.position}
                                                </div>
                                                )
                                                }
                                                else{
                                                    return(
                                                    <div className="pozBig">
                                                    {item.position}
                                                </div>
                                                    )
                                                }
                                                }
                                                )
                                                }
                                            </h4>
                                            <h4 className="ContainDet">
                                                {this.props.showRR[0].tracklist.map(item=>
                                                {
                                                    if(item.title.length<35)
                                                {
                                                return(
                                                <div className="tit">
                                                    {item.title}
                                                </div>
                                                )
                                                }
                                                else{
                                                    return(
                                                    <div className="titBig">
                                                    {item.title}
                                                </div>
                                                    )
                                                }
                                                }
                                                )
                                                }
                                            </h4>
                                            <h4 className="ContainDet">
                                                {this.props.showRR[0].tracklist.map(item=>
                                                {
                                                    if(item.title.length<35)
                                                {
                                                return(
                                                <div className="dur">
                                                    {item.duration}
                                                </div>
                                                )
                                                }
                                                else{
                                                    return(
                                                    <div className="durBig">
                                                    {item.duration}
                                                </div>
                                                    )
                                                }
                                                }
                                                )
                                                }
                                            </h4>
                                        </div>
                                        {this.props.showRR[0].buy==='' ?
                <div className="BuyButtonSection">
                <h3>
                Contact {titleUpper2.join(' ')} if you're interested in <a 
                className="BuyButton" 
                href={this.props.showRR[0].visit}
                >
                    this record
                </a>
            </h3>
            </div>
                :  <div className="BuyButtonSection">
                <h3>
                    Visit {titleUpper2.join(' ')}'s online store to <a 
                    className="BuyButton" 
                    href={this.props.showRR[0].buy}
                    >
                         buy this record
                    </a>
                </h3>
        
            </div>
                }
                                    </div>
                                </div>
                            </div>
                            );}
                    }

// The else if below is gonna get triggered when a hot record is clicked on, and details need to be displayed in a popup.

            else if(this.props.showHR)
            {   let titleUpper3=this.props.showHR.dealridName.map(
                item=>
                {
                let maj=item.charAt(0).toUpperCase()
                let restLet=item.slice(1,item.length)
                return(maj.concat(restLet))
                }
                )
                if(this.props.showHR.tracklist.length<12)
                {
    
                    return(
                    <div className='popup'>
                        <div className='InviL' onClick={this.props.closePopup}></div>
                        <div className="MainContentPopSmall">
                        <div className="ButCloseReco">
                        <img className="CloseStyle" src={Closebut} onTouchStart={this.props.closePopup} alt="closebutton"/></div>
                            <div className='CovAndInfSmall'>
                                <div className='CoverSmall'>
                                    <img 
                                    className="CoverSmallImg"
                                    src={this.props.showHR.thumbnail}
                                    height="140px"
                                    width="140px"
                                    alt="logo record"/>
                                </div>
                            <div className="ContainInfoTitSmall">
                                <h4>
                                    {this.props.showHR.artist}
                                </h4>
                                <h4 className="ProjTitle">
                                    {this.props.showHR.title}
                                </h4>
                                <br/><br/>
                            </div>
                            <div className="ContainFormatISmall">
                                <div className="FormatISmall">
                                    Release:
                                </div>
                                <div className="FormatISmall">
                                    Genre:
                                </div>
                                <div className="FormatISmall">
                                    Format:
                                </div>
                                <div className="FormatISmall">
                                    Label:
                                </div>
                                <div className="FormatISmall">
                                    Cat.:
                                </div>
                                <div className="FormatISmall">
                                    Country:
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showHR.date}
                                </div>
                                {this.props.showHR.styles ?
                                <div className="ReturnISmall">
                                {this.props.showHR.styles[0]} {this.props.showHR.styles[1]}
                            </div>
                                : <div className="ReturnISmall">
                                {this.props.showHR.genre[0]} {this.props.showHR.genre[1]}
                            </div>
                                }
                                <div className="ReturnISmall">
                                    {this.props.showHR.formats[0].descriptions[0]}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showHR.label}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showHR.catnb}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showHR.country}
                                </div>
                            </div>
                        </div>
                        <div className="ContainTheTrackL">
                            <div className="TracklistTit">
                                Tracklist
                            </div>
                            <div className="TracklistDetails">
                                <h4 className="ContainDet">
                                    {this.props.showHR.tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="poz">
                                            {item.position}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="pozBig">
                                            {item.position}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.showHR.tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="tit">
                                            {item.title}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="titBig">
                                            {item.title}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.showHR.tracklist.map(item=>
                                    {
                                        if(item.title.length<35)
                                        {
                                        return(
                                        <div className="dur">
                                            {item.duration}
                                        </div>
                                        )
                                        }
                                        else{
                                            return(
                                            <div className="durBig">
                                            {item.duration}
                                        </div>
                                            )
                                        }
                                    }
                                    )
                                    }
                                </h4>
                            </div>
                            {this.props.showHR.buy==='' ?
        <div className="BuyButtonSection">
        <h3>
        Contact {titleUpper3.join(' ')} if you're interested in <a 
        className="BuyButton" 
        href={this.props.showHR.visit}
        >
            this record
        </a>
    </h3>
    </div>
        :  <div className="BuyButtonSection">
        <h3>
            Visit {titleUpper3.join(' ')}'s online store to <a 
            className="BuyButton" 
            href={this.props.showHR.buy}
            >
                 buy this record
            </a>
        </h3>

    </div>
        }
                        </div>
                        </div>
                    </div>
                  );
            }
            else
            {
                return(
                    <div className='popup'>
                        <div className='InviL' onClick={this.props.closePopup}></div>
                        <div className="MainContentPopBig">
                        <div className="ButCloseReco">
                        <img className="CloseStyle" src={Closebut} onTouchStart={this.props.closePopup} alt="closebutton"/>
                        </div>
                            <div className='CovAndInfBig'>
                                <div className='CoverSmall'>
                                    <img
                                    className="CoverSmallImg" 
                                    src={this.props.showHR.thumbnail}
                                    height="140px"
                                    width="140px" 
                                    alt="logo record"/>
                                </div>
                            <div className="ContainInfoTitBig">
                                <h4>
                                    {this.props.showHR.artist}
                                </h4>
                                <h4 className="ProjTitle">
                                    {this.props.showHR.title}
                                </h4>
                                <br/><br/>
                            </div>
                            <div className="ContainFormatIBig">
                                <div className="FormatIBig">
                                    Release:
                                </div>
                                <div className="FormatIBig">
                                    Genre:
                                </div>
                                <div className="FormatIBig">
                                    Format:
                                </div>
                                <div className="FormatIBig">
                                    Label:
                                </div>
                                <div className="FormatIBig">
                                    Cat.:
                                </div>
                                <div className="FormatIBig">
                                    Country:
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showHR.date}
                                </div>
                                {this.props.showHR.styles ?
                                <div className="ReturnISmall">
                                {this.props.showHR.styles[0]} {this.props.showHR.styles[1]}
                            </div>
                                : <div className="ReturnISmall">
                                {this.props.showHR.genre[0]} {this.props.showHR.genre[1]}
                            </div>
                                }
                                <div className="ReturnISmall">
                                    {this.props.showHR.formats[0].descriptions[0]}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showHR.label}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showHR.catnb}
                                </div>
                                <div className="ReturnISmall">
                                    {this.props.showHR.country}
                                </div>
                            </div>
                        </div>
                        <div className="ContainTheTrackL">
                            <div className="TracklistTit">
                                Tracklist
                            </div>
                            <div className="TracklistDetails">
                                <h4 className="ContainDet">
                                    {this.props.showHR.tracklist.map(item=>
                                    {
                                        return(
                                        <div className="poz">
                                            {item.position}
                                        </div>
                                        )
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.showHR.tracklist.map(item=>
                                    {
                                        return(
                                        <div className="tit">
                                            {item.title}
                                        </div>
                                        )
                                    }
                                    )
                                    }
                                </h4>
                                <h4 className="ContainDet">
                                    {this.props.showHR.tracklist.map(item=>
                                    {
                                        return(
                                        <div className="dur">
                                            {item.duration}
                                        </div>
                                        )
                                    }
                                    )
                                    }
                                </h4>
                            </div>
                            {this.props.showHR.buy==='' ?
        <div className="BuyButtonSection">
        <h3>
        Contact {titleUpper3.join(' ')} if you're interested in <a 
        className="BuyButton" 
        href={this.props.showHR.visit}
        >
            this record
        </a>
    </h3>
    </div>
        :  <div className="BuyButtonSection">
        <h3>
            Visit {titleUpper3.join(' ')}'s online store to <a 
            className="BuyButton" 
            href={this.props.showHR.buy}
            >
                 buy this record
            </a>
        </h3>

    </div>
        }
                        </div>
                        </div>
                    </div>
                  );
            }
            }
            else
                {
// this part will return the popup called when a Hot Dealer is clicked on, and its details need to be displayed on screen. That's the reason why it also renders a videobar
// and a Recordbar
let titleUpper=this.props.showHD.name.map(
    item=>
    {
    let maj=item.charAt(0).toUpperCase()
    let restLet=item.slice(1,item.length)
    return(maj.concat(restLet))
    }
    )
                    return (
                    <div className='popup'>
                        
                        <div className='InviL' onClick={this.props.closePopup}></div>
                        <div className='popup_innerHD'>
                            <div className='ImgDet'>
                                <img 
                                className="ImgDetImg"
                                src={this.props.showHD.logo} 
                                alt="logo shop"/>
                            </div>
                            <div className="ButClose">
                            <img className="CloseStyle" src={Closebut} onTouchStart={this.props.closePopup} alt="closebutton"/>                             
                            </div>
                            {this.props.showHD.description.length<300?
                                 <div className="ContainerDealersD">
                                 <h4 className="DName">
                                     {titleUpper.join(' ')}
                                 </h4>
                                 <h6 className="AddOp">
                                     {this.props.showHD.address}<br/>
                                     {this.props.showHD.open}{this.props.showHD.holiday}
                                 </h6>
                                 <br/>
                                 <h4 className="DealerDesc">
                                     {this.props.showHD.description}
                                 </h4>
                                 <br/>
                                 <a className="LinkShop" href={this.props.showHD.url}>
                                     Website
                                 </a>
                             </div>:
                              <div className="ContainerDealersD">
                              <h4 className="DName">
                                  {titleUpper.join(' ')}
                              </h4>
                              <h6>
                                  {this.props.showHD.address}<br/>
                                  {this.props.showHD.open}{this.props.showHD.holiday}
                              </h6>
                              <br/>
                              <h4 className="DealerDescBig">
                                  {this.props.showHD.description}
                              </h4>
                              <br/>
                              <a className="LinkShop" href={this.props.showHD.url}>
                                  Website
                              </a>
                          </div>}
                       
                            <div className="ContainerForVidBar">
                                <VideoBar
                                show={this.props.showHD}
                                vidForHD={this.props.vidForHD}
                                showSVP={this.props.showSVP}
                                gifHDRR={this.props.gifHDRR}
                                />
                            </div>
                            <div className="Container4RecBar">
                                <RecordBar
                                showDDRR={this.props.showHD}
                                recoForHD={this.props.recoForHD} 
                                showSRP={this.props.showSRP} 
                                gifHDRR={this.props.gifHDRR}  
                                />
                            </div>
                        </div>
                    
                    </div>
                    );
                }
            }
}
  
  export default Popup;