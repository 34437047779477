import React from 'react';
import './AboutUs.css';
import Alixkun from './alixkun.jpg';
import YT from './YTIcon.png';
import Insta from './InstaIcon.png';

class AboutUs extends React.Component{

    render()
    {
      return(
      <div className="AboutUs">

        <h2 className="Picture">
          <img 
          className="ImgAU"
          src={Alixkun} 
          height="400" 
          alt="picalix"
          />
        </h2>
        <h3 className="WhoWeAre">
        We have decided to create this platform to support record shops/individual record dealers and help them get a better visibility.
        the idea came during the pandemic, as a lot of our friends running record businesses were struggling, and we were feeling the frustration of not being able to go to our favorite record shops and discover new music.
        That's how My Record Dealer was born.It's still heavily under construction, and it's basically a one man team for now, but we'll make it better little by little.
        Contact us if you're willing to help or take part to this platform, or if you run a record business and want to get in touch.
        </h3>
        <div className="Contact">
          <br/><br/>
          email<br/>
          contact@myrecorddealer.com 
        </div>
        <div className="SnsLinks">
          <a href="https://www.youtube.com/channel/UCISFB0ITQe5CONiurQf2yDA"><img src={YT} className="IconAUYT" height="45" width="60" alt="Icon YT"/></a>
          <a href="https://www.instagram.com/myrecorddealer/"><img src={Insta} className="IconAUI" height="50" width="50" alt="Icon Insta"/></a>
        </div>

      </div>
      );
    }
}  
  
export default AboutUs;