import React from 'react';
import './SearchBarVideo.css';

class SearchBarVideo extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            term: '',
            placeholder:"Shop, Genre or Keyword"
          };
        
    this.handleTermChange = this.handleTermChange.bind(this);
    this.searchVideo = this.searchVideo.bind(this);
    this.changePH=this.changePH.bind(this);
    this.maybeHandleSearch=this.maybeHandleSearch.bind(this);
    }

    changePH(){
        this.setState({
          placeholder:''
        })
      }

    searchVideo()

        {
          let prelim=this.state.term;
          
          
          let allTerms = prelim.split(" ");
            let allTermslc =[];
            allTerms.map(item=>
              {
                let output=item.toLowerCase();
                allTermslc.push(output);
              }
            )
            this.props.onSearch(allTermslc);
            this.setState({term: allTermslc});
        }



    

    handleTermChange(event) {
        this.setState({term: event.target.value});
      }

    maybeHandleSearch(e){
      if (e.key === 'Enter') {
          e.preventDefault();
          this.searchVideo();
          return;
        }
       
      }
    
    render(){
return(<div className="SearchBarVideo">
  <input className="InputBar" onKeyDown={this.maybeHandleSearch} placeholder={this.state.placeholder} onClick={this.changePH} onChange={this.handleTermChange} />
  <button className="SearchButVideo" onClick={this.searchVideo}>search</button>
</div>);
    }
};

export default SearchBarVideo;