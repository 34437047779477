import React from 'react';
import SearchBarDealers from '../SearchBarDealers/SearchBarDealers.js';
import SearchDealersResults from '../SearchDealersResults/SearchDealersResults';
import DealerDetailsList from '../DealerDetailsList/DealerDetailsList';
import './Dealers.css';


class Dealers extends React.Component{

render()
{
    return(
    <div className="OriginalComponents">

        <div className="SBDealersSpace">
            <SearchBarDealers 
            onSearch={this.props.search}
            />
        </div>
        
            <div className="SDResults">
                <SearchDealersResults 
                data={this.props.state.searchResults} 
                getId={this.props.changeState}
                />
            </div>
            <div className="DDList">
                <DealerDetailsList 
                vidToShow={this.props.state.vidToShow} 
                showVidPop={this.props.state.showVidPopup} 
                showPop={this.props.state.showPopup} 
                change={this.props.change} 
                recoTR={this.props.state.recoToRender} 
                show={this.props.state.objectToRender}
                />
            </div>
        

    </div>
    );
}
}

export default Dealers;